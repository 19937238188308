import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./styles.module.css";

const Loader = (props) => {
  const { containerStyle, loaderStyle } = props;
  return (
    <div className={classNames(styles.containerStyle, containerStyle)}>
      <div className={classNames(styles.loaderStyle, loaderStyle)} />
    </div>
  );
};

Loader.propTypes = {
  containerStyle: PropTypes.string,
  loaderStyle: PropTypes.string,
};

export default Loader;
