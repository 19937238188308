import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'providers/authprovider';
import Loader from 'components/common/loader';

const GuestGuard = (props) => {
  const { children } = props;
  const { user, isInitialized, setAuthError } = useAuth();

  let homeRoute = '/';

  if (!isInitialized) {
    return <Loader />;
  }

  if (user) {
    return <Navigate to={homeRoute} />;
  }
  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
