import AppRoutes from 'routing';
import { AppDataProvider } from 'providers/appdataprovider';
import UserDataProvider from 'providers/userdataprovider';
import AuthProvider from 'providers/authprovider';
import SocketProvider from 'providers/socketprovider';

function App() {
  return (
    <AppDataProvider>
      <AuthProvider>
        <UserDataProvider>
          <SocketProvider>
            <AppRoutes />
          </SocketProvider>
        </UserDataProvider>
      </AuthProvider>
    </AppDataProvider>
  );
}

export default App;
