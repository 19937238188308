import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/common/image';
import {
  arrowDownFillBlackIcon,
  chevronLeftBlackIcon,
  closeGrayIcon,
  deleteBlackIcon,
  electronicsIcon,
  packIcon,
  uploadBlackIcon,
} from 'resources/images';
import classNames from 'classnames';
import Input from 'components/common/input';
import Capsule from 'components/common/capsule';
import { addItemApi, updateItemApi } from 'networking/api/myItems';
import { getItemIcon } from 'functions';
import Button from 'components/common/button';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ImagePreview from 'components/common/image-preview';
import { uploadImageApi } from 'networking/api/user';
import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';
import ProgressBar from 'components/common/progress-bar';
import AWS from 'aws-sdk';
import styles from './styles.module.css';
import { useAuth } from 'providers/authprovider';
import Loader from 'components/common/loader';
import { useNavigate } from 'react-router';
import routePaths from 'routing/paths';
import { useUserData } from 'providers/userdataprovider';

const AddorEditItem = (props) => {
  const { item, closeAction = () => {} } = props;
  const { width } = useWindowDimensions();
  const { user } = useAuth();
  const { categories, setMyItems } = useUserData();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState('Basic info');
  const [itemData, setItemData] = useState({
    product_name: item?.product_name || '',
    category: item?.category
      ? typeof item.category === 'object'
        ? item.category._id
        : item.category
      : '' || '',
    product_image: item?.product_image || '',
    note: item?.note || '',
    owner_id: user?._id,
    sno: item.sno,
  });
  const [error, setError] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const [imageURI, setImageURI] = useState(item?.product_image || '');
  const [selctedFile, setSelectedFile] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showUploadPreview, setShowUploadPreview] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imgProgress, setImgProgress] = useState(0);
  const [isBtnDisable, setIsBtnDisable] = useState(false);

  const fileInputRef = useRef();

  let tabOptions = ['Basic info', 'Item image', 'Note to finder'];
  let demoNotes = [
    'Text me if you find this item',
    'Please contact me if you find this item, thanks',
  ];

  useEffect(() => {
    if (activeStep === 'Basic info') {
      if (itemData.product_name === '' || itemData.category === '') {
        setIsBtnDisable(true);
      } else {
        setIsBtnDisable(false);
      }
    } else if (activeStep === 'Item image') {
      setIsBtnDisable(false);
    } else {
      if (itemData.note === '') {
        setIsBtnDisable(true);
      } else {
        setIsBtnDisable(false);
      }
    }
  }, [itemData, activeStep]);

  const handleNextAction = () => {
    if (activeStep === 'Basic info') {
      setActiveStep('Item image');
    } else if (activeStep === 'Item image') {
      setActiveStep('Note to finder');
    } else {
      setIsPreview(true);
    }
  };

  const handleBackAction = () => {
    if (item?._id) {
      closeAction();
    } else {
      if (activeStep === 'Note to finder') {
        setActiveStep('Item image');
      } else if (activeStep === 'Item image') {
        setActiveStep('Basic info');
      } else {
        closeAction();
      }
    }
  };

  const handleUploadImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUploadSelection = (e) => {
    const file = e.target.files[0];
    console.log('file', file);
    setSelectedFile(file);
    setImageURI(URL.createObjectURL(file));
    setShowUploadPreview(true);
    // handleUploadTicketSolvedImg(file);
  };

  const handleRemoveImage = () => {
    setImageURI(null);
    setSelectedFile(null);
    fileInputRef.current.value = '';
    setImgProgress(0);
  };

  const handleConfirmAction = async () => {
    setIsLoading(true);
    try {
      let res = item?._id
        ? await updateItemApi({
            product_id: item._id,
            product_name: itemData.product_name,
            product_image: itemData.product_image,
            note: itemData.note,
            category: itemData.category,
          })
        : await addItemApi(itemData);
      if (res.data.type === 'success') {
        console.log('response', res.data.data);
        if (!item?._id) {
          setMyItems((prev) => [res.data.data, ...prev]);
          navigate(routePaths.myItems);
        } else {
          setMyItems((prev) => {
            let prev_data = [...prev];
            let itemIndex = prev_data.findIndex((el) => el._id === item._id);
            if (itemIndex !== -1) {
              prev_data[itemIndex] = { ...prev_data[itemIndex], ...itemData };
            }
            return prev_data;
          });
        }
        setIsLoading(false);
        closeAction();
      } else {
        setError(res.data.message);
        setIsLoading(false);
        console.log('add product api failed', res.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
      console.log('add product api catch', error);
    }
  };

  const uploadFile = async () => {
    setIsBtnDisable(true);
    // S3 Bucket Name
    const S3_BUCKET = 'files.foundiapp.com';
    // S3 Region
    const REGION = 'eu-north-1';
    // S3 Credentials
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    // Files Parameters
    const params = {
      Bucket: S3_BUCKET,
      Key: 'file-uploads/' + selctedFile?.name,
      Body: selctedFile,
    };
    setImageUploading(true);
    // Uploading file to s3
    var upload = s3
      .putObject(params)
      .on('httpUploadProgress', (evt) => {
        // File uploading progress
        setImgProgress(parseInt((evt.loaded * 100) / evt.total));
      })
      .promise();

    await upload.then((err, data) => {
      setIsBtnDisable(false);
      console.log(err);
      // Fille successfully uploadedc
      setImageURI(
        `https://s3.${REGION}.amazonaws.com/${S3_BUCKET}/${'file-uploads/' + selctedFile?.name}`
      );
      setItemData({
        ...itemData,
        product_image: `https://s3.${REGION}.amazonaws.com/${S3_BUCKET}/${
          'file-uploads/' + selctedFile?.name
        }`,
      });
      setImageUploading(false);
    });
  };

  const findCategoryName = () => {
    let findCat = categories?.find((item) => item._id === itemData.category);
    console.log('findCat', findCat);
    return findCat.title;
  };

  //   ui section

  const renderContentSection = () => {
    return (
      <div className={styles.contentViewStyle}>
        {activeStep === 'Basic info' && renderBasicInfoSection()}
        {activeStep === 'Item image' && renderImageSection()}
        {activeStep === 'Note to finder' && renderNoteSection()}
      </div>
    );
  };

  const renderBasicInfoSection = () => {
    return (
      <React.Fragment>
        <Input
          labelText="Item name*"
          inputLabelStyle={styles.labelTextStyle}
          placeholder="Enter name here"
          value={itemData.product_name}
          onChange={(e) => setItemData({ ...itemData, product_name: e.target.value })}
        />
        <div className={styles.categoryViewStyle}>
          <p className={styles.labelTextStyle}>Select category*</p>
          <div className={styles.categoriesViewStyle}>
            {categories?.length > 0 &&
              categories?.map((cat, index) => (
                <Capsule
                  key={index}
                  label={cat.title}
                  labelStyle={classNames(
                    styles.categoryTextStyle,
                    itemData.category === cat._id && styles.selectedCatTextStyle
                  )}
                  leftIcon={getItemIcon(cat.title)}
                  leftIconStyle={styles.categoryIconStyle}
                  containerStyle={classNames(
                    styles.eachCategoryViewStyle,
                    itemData.category === cat._id && styles.selectedCatStyle
                  )}
                  onClick={() => setItemData({ ...itemData, category: cat._id })}
                />
              ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderImageSection = () => {
    return (
      <React.Fragment>
        <input
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          placeholder="Upload an image"
          ref={fileInputRef}
          onChange={handleImageUploadSelection}
          hidden
        />
        {!selctedFile && !imageURI ? (
          <div className={styles.uploadImageContainerStyle}>
            <div className={styles.imageUploadSubViewStyle}>
              <p className={styles.imageUploadTitelTextStyle}>Upload image</p>
              <p className={styles.imageUploadDescTextStyle}>
                Only .jpg and .png files. 5 MB max file size.
              </p>
            </div>
            <Button
              title="Choose file"
              onClick={() => {
                handleUploadImageClick();
              }}
              leftIcon={uploadBlackIcon}
              btnStyle={styles.uploadBtnStyle}
            />
          </div>
        ) : (
          <div className={styles.imageContainerStyle}>
            <div className={styles.imgSubContainerStyle}>
              <div className={styles.imgDetailsStyle}>
                <Image src={imageURI} containerStyle={styles.imgViewStyle} />
                {!item?._id && (
                  <div className={styles.imgTitleAndSizeViewStyle}>
                    <p className={styles.imgTitleTextStyle}>{selctedFile?.name}</p>
                    <p className={styles.imgSizeTextStyle}>
                      Size: {(selctedFile?.size / 1024 / 1024).toFixed(2)} MB
                    </p>
                  </div>
                )}
              </div>
              <Image
                src={imgProgress !== 100 ? closeGrayIcon : deleteBlackIcon}
                containerStyle={styles.closeIconStyle}
                onClick={() => handleRemoveImage()}
              />
            </div>
            {imgProgress !== 100 && imageUploading && (
              <ProgressBar size={20} percentage={imgProgress || 0} />
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderNoteSection = () => {
    return (
      <div className={styles.noteSectionStyle}>
        <Input
          type="textarea"
          labelText="Description*"
          inputLabelStyle={styles.labelTextStyle}
          placeholder="Enter here"
          value={itemData.note}
          onChange={(e) => setItemData({ ...itemData, note: e.target.value })}
          inputStyle={styles.noteInputStyle}
        />
        <div className={styles.noteExamplesViewStyle}>
          {demoNotes.map((item, index) => (
            <div
              key={index}
              className={classNames(
                styles.eachNoteViewStyle,
                itemData.note === item && styles.activeNoteStyle
              )}
              onClick={() => setItemData({ ...itemData, note: item })}
            >
              <p className={styles.sampleNoteTextStyle}>{item}</p>
            </div>
          ))}
          <p></p>
        </div>
      </div>
    );
  };

  const renderBtns = () => {
    return (
      <div className={styles.btnsContainerStyle}>
        <p className={styles.errorTextStyle}>{error}</p>
        <div className={styles.btnsStyle}>
          {(!isPreview || item?._id) && (
            <Button
              title={activeStep === 'Basic info' || item?._id ? 'Cancel' : 'Back'}
              variant="light"
              onClick={() => handleBackAction()}
              btnStyle={styles.cancelBtnStyle}
              size={width > 576 ? 'lg' : 'md'}
            />
          )}
          <Button
            onClick={() => {
              isPreview ? handleConfirmAction() : handleNextAction();
            }}
            title={
              activeStep !== 'Note to finder'
                ? 'Next'
                : !isPreview
                ? 'Preview'
                : item?._id
                ? 'Save changes'
                : 'Confirm'
            }
            size={width > 576 ? 'lg' : 'md'}
            disabled={isBtnDisable}
          />
        </div>
      </div>
    );
  };

  const renderPreviewSection = () => {
    return (
      <div className={styles.subContainerStyle}>
        <div className={styles.previewHeaderStyle}>
          <Image src={chevronLeftBlackIcon} onClick={() => setIsPreview(false)} />
          <p className={styles.headerTextStyle}>Preview</p>
        </div>
        <div className={styles.itemDetailsStyle}>
          <Image src={itemData?.product_image || packIcon} containerStyle={styles.itemImageStyle} />
          <div className={styles.itemDetailsSubWrapperStyle}>
            <p className={styles.headerTextStyle}>{itemData.product_name}</p>
          </div>
          <div className={styles.otherDetailsViewStyle}>
            <p className={styles.itemDetailsLableStyle}>
              Unique ID:&nbsp;<span>{item.sno}</span>
            </p>
            <p className={styles.itemDetailsLableStyle}>
              Category:&nbsp;<span>{findCategoryName()}</span>
            </p>
            <p className={styles.itemDetailsLableStyle}>
              Note:&nbsp;<span>{itemData.note}</span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {isLoading && <Loader />}
      {!isPreview ? (
        <div className={styles.subContainerStyle}>
          {/* header section */}
          <div className={styles.headerViewStyle}>
            <p className={styles.headerTextStyle}>{item?._id ? 'Edit item' : 'Add item'}</p>
            <div className={styles.headerRightWrapperStyle}>
              {/* <p>676876</p> */}
              <Capsule
                label={item.sno}
                labelStyle={styles.snoTextStyle}
                leftIcon={arrowDownFillBlackIcon}
                leftIconStyle={styles.snoArrowStyle}
                containerStyle={styles.snoViewStyle}
              />
              <Image
                src={closeGrayIcon}
                onClick={closeAction}
                containerStyle={styles.closeIconStyle}
              />
            </div>
          </div>
          {/* tab sections  */}
          <div className={styles.tabSectionStyle}>
            {tabOptions.map((opt, index) => (
              <p
                key={index}
                className={classNames(
                  styles.tabTextStyle,
                  activeStep === opt && styles.activeTabStyle
                )}
              >
                {opt}
              </p>
            ))}
          </div>
          {/* details dection */}
          {renderContentSection()}
        </div>
      ) : (
        renderPreviewSection()
      )}
      {renderBtns()}
      {showUploadPreview && (
        <ImagePreview
          src={imageURI}
          onClose={() => {
            handleRemoveImage();
            setShowUploadPreview(false);
          }}
          onDone={() => {
            setShowUploadPreview(false);
            uploadFile();
          }}
        />
      )}
      {showPreview && (
        <ImagePreview
          src={imageURI}
          onClose={() => setShowPreview(false)}
          onDone={() => setShowPreview(false)}
        />
      )}
    </div>
  );
};

AddorEditItem.propTypes = {
  item: PropTypes.object,
  closeAction: PropTypes.func,
};

export default AddorEditItem;
