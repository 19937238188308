import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Image from 'components/common/image';
import {
  checkWhiteIcon,
  deleteBlackIcon,
  hideGrayIcon,
  pencilEditGrayIcon,
  showGrayIcon,
} from 'resources/images';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Avatar from 'components/common/avatar';
import { useAuth } from 'providers/authprovider';
import Button from 'components/common/button';
import Input from 'components/common/input';
import { useStrings } from 'providers/stringsprovider';
import styles from './styles.module.css';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { updatePasswordApi, updateUserDataApi, uploadImageApi } from 'networking/api/user';
import ImagePreview from 'components/common/image-preview';

const Profile = () => {
  const { user, setUser } = useAuth();
  const { strings } = useStrings();
  const { width } = useWindowDimensions();
  const [activeTab, setActiveTab] = useState('Profile details');
  const [fullName, setFullName] = useState('');
  const [email, setEmaill] = useState('');
  const [oldPwVisible, setOldPwVisible] = useState(false);
  const [newPwVisible, setNewPwVisible] = useState(false);
  const [confirmPwVisible, setConfirmPwVisible] = useState(false);
  const [apiError, setApiError] = useState('');
  const [btnLoader, setBtnLoader] = useState(false);
  const [imageURI, setImageURI] = useState(user?.image || '');
  const [selctedFile, setSelectedFile] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showUploadPreview, setShowUploadPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let tabOptions = ['Profile details', 'Security'];

  const fileInputRef = useRef();

  useEffect(() => {
    if (user) {
      setFullName(user?.full_name);
      setEmaill(user?.email);
    }
  }, [user]);

  const [schemaObj, setSchemaObj] = useState({
    old_password: Yup.string().required('Old passwoord is required'),
    new_password: Yup.string()
      .min(6, strings.passwordValidationText)
      .matches(/[A-Z]/, strings.passWordNeedUpperCaseText)
      .required(strings.passwordRequired),
    confirm_password: Yup.string()
      .required('Please enter your password.')
      .oneOf([Yup.ref('new_password')], 'Your passwords do not match.'),
  });

  const updatePasswordAction = async (values, { resetForm }) => {
    setBtnLoader(true);
    try {
      let response = await updatePasswordApi(values);
      if (response.data.type === 'success') {
        setBtnLoader(false);
        resetForm();
      } else {
        setBtnLoader(false);
        console.log('update password api failed', response.data.message);
      }
    } catch (error) {
      console.log('update password api catch', error);
    }
  };

  const passwordSchema = Yup.object().shape(schemaObj);
  const formik = useFormik({
    initialValues: {
      user_id: user?._id,
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema: passwordSchema,
    onSubmit: updatePasswordAction,
  });

  const saveChangesAction = async () => {
    if (user?.full_name !== fullName || user?.email !== email) {
      try {
        setBtnLoader(true);
        let data = {
          full_name: fullName,
          user_id: user?._id,
        };
        let res = await updateUserDataApi(data);
        if (res.data.type === 'success') {
          setUser(res.data.data);
          setBtnLoader(false);
        } else {
          setBtnLoader(false);
          console.log('update api failed', res.data.message);
        }
      } catch (error) {
        setBtnLoader(false);
        console.log('catch error', error);
      }
    } else {
      console.log('nothing done');
    }
  };

  const handleUploadImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUploadSelection = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setImageURI(URL.createObjectURL(file));
    setShowUploadPreview(true);
    // handleUploadTicketSolvedImg(file);
  };

  const handleRemoveImage = () => {
    setImageURI(null);
    setSelectedFile(null);
    fileInputRef.current.value = '';
  };

  const handleUploadImg = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('image', selctedFile);
      const response = await uploadImageApi(formData);
      if (response.data.type === 'success') {
        setImageURI(response.data.data);
        setIsLoading(false);
        setShowUploadPreview(false);
      } else {
        console.log('error', response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  // ui section

  const renderTabSection = () => {
    return (
      <div className={styles.tabSectionStyle}>
        {tabOptions.map((opt, index) => (
          <p
            key={index}
            onClick={() => {
              setActiveTab(opt);
              formik.resetForm();
            }}
            className={classNames(styles.tabTextStyle, activeTab === opt && styles.activeTabStyle)}
          >
            {opt}
          </p>
        ))}
      </div>
    );
  };

  const renderProfileSection = () => {
    return (
      <div className={styles.profileSectionStyle}>
        <Avatar
          src={imageURI}
          label="Halk"
          containerStyle={styles.profileImgStyle}
          imgStyle={styles.avataImgStyle}
        />
        <div className={styles.labelAndUploadViewStyle}>
          <p className={styles.labelTextStyle}>Min. 256×256px .PNG or .JPEG</p>
          <div className={styles.uploadAndDeleteSectinStyle}>
            <input
              type="file"
              accept="image/jpeg, image/jpg, image/png"
              placeholder="Upload an image"
              ref={fileInputRef}
              onChange={handleImageUploadSelection}
              hidden
            />
            <Button
              title="Upload new"
              onClick={() => handleUploadImageClick()}
              btnStyle={styles.uploadBtnStyle}
            />
            <Image src={deleteBlackIcon} alt="delete" containerStyle={styles.deleteIconStyle} />
          </div>
        </div>
      </div>
    );
  };

  const renderInputSection = () => {
    return (
      <div className={styles.inputContainerStyle}>
        <div className={styles.inputSubContainerStyle}>
          <Input
            name="fullName"
            labelText={strings.fullName}
            inputLabelStyle={styles.customLabelTextStyle}
            placeholder={strings.enterFullName}
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            rightIcon={pencilEditGrayIcon}
          />
          <Input
            name="email"
            labelText={strings.email}
            inputLabelStyle={styles.customLabelTextStyle}
            placeholder={strings.enterEmail}
            value={email}
            onChange={(e) => setEmaill(e.target.value)}
            rightIcon={pencilEditGrayIcon}
            disabled={true}
          />
        </div>
        <div className={styles.btnSectionStyle}>
          <Button
            title="Save changes"
            size={width > 576 ? 'lg' : 'md'}
            onClick={() => saveChangesAction()}
            leftIcon={checkWhiteIcon}
            isLoading={btnLoader}
          />
        </div>
      </div>
    );
  };

  const renderSecuritySection = () => {
    return (
      <form onSubmit={formik.handleSubmit} className={styles.inputContainerStyle}>
        <div className={styles.inputSubContainerStyle}>
          <Input
            name="old_password"
            type={oldPwVisible ? 'text' : 'password'}
            labelText={'Old password'}
            inputLabelStyle={styles.customLabelTextStyle}
            placeholder={'Oldd password here'}
            value={formik.values.old_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.old_password && formik.errors.old_password
                ? formik.errors.old_password
                : ''
            }
            rightIcon={oldPwVisible ? showGrayIcon : hideGrayIcon}
            onClickRightIcon={() => setOldPwVisible(!oldPwVisible)}
          />
          <Input
            name="new_password"
            type={newPwVisible ? 'text' : 'password'}
            labelText={'New password'}
            inputLabelStyle={styles.customLabelTextStyle}
            placeholder={strings.enterPassword}
            value={formik.values.new_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.new_password && formik.errors.new_password
                ? formik.errors.new_password
                : ''
            }
            rightIcon={newPwVisible ? showGrayIcon : hideGrayIcon}
            onClickRightIcon={() => setNewPwVisible(!newPwVisible)}
          />
          <Input
            name="confirm_password"
            type={confirmPwVisible ? 'text' : 'password'}
            labelText={'Confirm password'}
            inputLabelStyle={styles.customLabelTextStyle}
            placeholder={'Re-enter new password'}
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.confirm_password && formik.errors.confirm_password
                ? formik.errors.confirm_password
                : ''
            }
            rightIcon={confirmPwVisible ? showGrayIcon : hideGrayIcon}
            onClickRightIcon={() => setConfirmPwVisible(!confirmPwVisible)}
          />
          {<p className={styles.errorTextStyle}>{apiError}</p>}
        </div>
        <div className={styles.btnSectionStyle}>
          <Button
            title="Save changes"
            type="submit"
            size={width > 576 ? 'lg' : 'md'}
            leftIcon={checkWhiteIcon}
            isLoading={btnLoader}
          />
        </div>
      </form>
    );
  };
  return (
    <div className={styles.containerStyle}>
      <div className={styles.headerViewStyle}>
        <p className={styles.headerTextStyle}>Edit profile</p>
      </div>
      <div className={styles.middleSectionStyle}>
        {renderTabSection()}
        {activeTab === 'Profile details' ? (
          <React.Fragment>
            {renderProfileSection()}
            {renderInputSection()}
          </React.Fragment>
        ) : (
          renderSecuritySection()
        )}
      </div>
      {showUploadPreview && (
        <ImagePreview
          src={imageURI}
          onClose={() => {
            handleRemoveImage();
            setShowUploadPreview(false);
          }}
          onDone={() => handleUploadImg()}
          isLoading={isLoading}
        />
      )}
      {showPreview && (
        <ImagePreview
          src={imageURI}
          onClose={() => setShowPreview(false)}
          onDone={() => setShowPreview(false)}
        />
      )}
    </div>
  );
};

export default Profile;
