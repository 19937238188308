import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import NavBar from 'components/navbar';
import Image from 'components/common/image';
import classNames from 'classnames';
import {
  chevronLeftWhiteIcon,
  frameWhiteIcon,
  itemsGrayIcon,
  productSampleIcon,
  verticalMenuWhiteIcon,
} from 'resources/images';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'components/common/button';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useUserData } from 'providers/userdataprovider';
import { addToCartApi } from 'networking/api/cart';
import { buyItemsApi } from 'networking/api/order';
import routePaths from 'routing/paths';

const Products = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { products, setProducts, setCartData, setOrders } = useUserData();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedId = searchParams.get('selected');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cartLoading, setCartLoading] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (selectedId) {
      setSelectedProduct(products.find((item) => item._id === selectedId));
    } else {
      setSelectedProduct(null);
    }
  }, [selectedId, products]);

  useEffect(() => {
    if (successMessage || errorMessage) {
      setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 5000);
    }
  }, [successMessage, errorMessage]);

  const displayPrice = (val, currency = 'usd') => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(val);
  };

  async function addItemToCart() {
    try {
      setCartLoading(true);
      let res = await addToCartApi({ product_id: selectedProduct._id, quantity: 1 });
      const { type, data, message } = res.data;
      if (type === 'success') {
        setCartData(data);
        setSuccessMessage('Item added to cart');
      } else {
        setErrorMessage(message || 'Something went wrong');
      }
      setCartLoading(false);
    } catch (error) {
      setCartLoading(false);
      setErrorMessage(error.message);
      console.log('Update quantity catch', error.message);
    }
  }

  async function handlePurchaseItem() {
    try {
      setPurchaseLoading(true);
      let res = await addToCartApi({ product_id: selectedProduct._id, quantity: 1 });
      const { type, data, message } = res.data;
      if (type === 'success') {
        setCartData(data);
        navigate(routePaths.checkout);
      } else {
        setErrorMessage(message || 'Something went wrong');
      }
      setPurchaseLoading(false);
    } catch (error) {
      setPurchaseLoading(false);
      setErrorMessage(error.message);
      console.log('Update quantity catch', error.message);
    }
    // try {
    //   setPurchaseLoading(true);
    //   let res = await buyItemsApi({
    //     products: [{ product_id: selectedProduct._id, quantity: 1 }],
    //     currency: selectedProduct.price.currency,
    //   });
    //   const { type, data, message } = res.data;
    //   if (type === 'success') {
    //     setOrders((prev) => [data, ...prev]);
    //     setSuccessMessage(message || 'Item purchased successfully');
    //   } else {
    //     setErrorMessage(message || 'Something went wrong');
    //   }
    //   setPurchaseLoading(false);
    // } catch (error) {
    //   setPurchaseLoading(false);
    //   setErrorMessage(error.message);
    //   console.log('Update quantity catch', error.message);
    // }
  }

  const renderLeftSection = () => {
    return (
      <div className={styles.leftSectionStyle}>
        <div className={styles.leftSectionHeaderBlockStyle}>
          <p className={styles.leftSectionHeaderTitleStyle}>Products</p>
        </div>
        <div className={styles.itemsWrapperStyle}>
          {products.map((item, index) => {
            return (
              <div
                key={index}
                className={classNames(
                  styles.eachItemStyle,
                  item._id === selectedProduct?._id && styles.selectedItemStyle
                )}
                onClick={() => setSearchParams({ selected: item._id })}
              >
                <Image src={item.images.thumbnail} containerStyle={styles.itemCategoryIconStyle} />
                <div className={styles.productDataBlockStyle}>
                  <p className={styles.productTitleStyle}>{item.name}</p>
                  <p className={styles.productDescStyle}>
                    Category:
                    <span>{item.category?.title || 'Uncategorized'}</span>
                    {/* {displayPrice(item.price.selling_price, item.price.currency)}
                    <span>{displayPrice(item.price.original_price, item.price.currency)}</span> */}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderRightSection = () => {
    return (
      <div className={styles.rightSectionStyle}>
        {!selectedId ? (
          renderEmptySelectedItemContainer()
        ) : (
          <React.Fragment>
            <div className={styles.itemImgSectionStyle}>
              <Image
                src={selectedProduct?.images?.thumbnail}
                alternateSrc={productSampleIcon}
                containerStyle={styles.itemImgStyle}
                imgStyle={styles.imgStyle}
              />
              <div className={styles.rightHeaderBtnsWrapperStyle}>
                <Image
                  src={width <= 867 ? chevronLeftWhiteIcon : frameWhiteIcon}
                  containerStyle={styles.rightHeaderbtnStyle}
                  imgStyle={styles.iconStyle}
                  onClick={() => (width <= 867 ? navigate(-1) : console.log('mmmm'))}
                />
                <Image
                  src={verticalMenuWhiteIcon}
                  containerStyle={styles.rightHeaderbtnStyle}
                  imgStyle={styles.iconStyle}
                  // onClick={() => setItemOptionsModal(true)}
                  // reference={setItemOptionsRef}
                />
              </div>
            </div>
            {/* item ddetails section  */}
            <div className={styles.itemWrapperStyle}>
              <p className={styles.labelTextStyle}>Overview</p>
              <div className={styles.itemDetailsWrapperStyle}>
                <div className={styles.itemDataStyle}>
                  <p className={styles.titleTextStyle}>{selectedProduct?.name}</p>
                  <p className={styles.descTextStyle}>{selectedProduct?.short_description}</p>
                </div>
                <div className={styles.priceSectionStyle}>
                  <p className={styles.offerPriceTextStyle}>
                    {displayPrice(
                      selectedProduct?.price.selling_price,
                      selectedProduct?.price.currency
                    )}
                  </p>
                  <p className={styles.originalPriceTextStyle}>
                    {displayPrice(
                      selectedProduct?.price.original_price,
                      selectedProduct?.price.currency
                    )}
                  </p>
                </div>
                <div className={styles.btnsViewStyle}>
                  <Button
                    title="Add to cart"
                    btnStyle={styles.btnStyle}
                    variant="light"
                    onClick={addItemToCart}
                    isLoading={cartLoading}
                    disabled={cartLoading}
                  />
                  <Button
                    title="Buy now"
                    btnStyle={styles.btnStyle}
                    onClick={handlePurchaseItem}
                    isLoading={purchaseLoading}
                    disabled={purchaseLoading}
                  />
                </div>
                {successMessage && <p className={styles.successMsgStyle}>{successMessage}</p>}
                {errorMessage && <p className={styles.errorMsgStyle}>{errorMessage}</p>}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };
  const renderEmptySelectedItemContainer = () => {
    return (
      <div className={styles.emptyItemContainerStyle}>
        <Image src={itemsGrayIcon} />
        <p className={styles.emptyContainerTextStyle}>
          Click on the individual product to see more details
        </p>
      </div>
    );
  };
  return (
    <div className={styles.containerStyle}>
      <NavBar />
      <div className={styles.subContainerStyle}>
        {renderLeftSection()}
        {(selectedProduct || width > 867) && renderRightSection()}
      </div>
    </div>
  );
};

export default Products;
