import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { useAuth } from 'providers/authprovider';
import { getChatListApi } from 'networking/api/chat';
import { useRef } from 'react';
import { getCategoriesApi, getItemsApi } from 'networking/api/myItems';
import { getUserCartApi } from 'networking/api/cart';
import { getEcomProductsApi } from 'networking/api/ecom';
import { getOrdersApi } from 'networking/api/order';

const UserDataContext = createContext();

const UserDataProvider = (props) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [myItems, setMyItems] = useState([]);
  const [cartData, setCartData] = useState(null);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const chatListRef = useRef([]);

  useEffect(() => {
    if (user) {
      getChatList();
      getCategories();
      getItems();
      getCartItems();
      getOrders();
    } else {
      setMyItems([]);
      setChatList([]);
      setOrders([]);
      setCartData([]);
    }
    getProducts();
  }, [user]);

  async function getChatList() {
    try {
      setLoading(true);
      let response = await getChatListApi(user?._id);
      if (response.data.type === 'success') {
        setChatList(response.data.data);
        chatListRef.current = response.data.data;
      } else {
        console.log('getAssetsChatListApi failed', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //   setIsShowLoading(false);
      console.log('getAssetsChatListApi catch', error.message);
    }
  }

  async function getCategories() {
    try {
      let res = await getCategoriesApi();
      if (res.data.type === 'success') {
        setCategories(res.data.data);
      } else {
        console.log('fails', res.data.message);
      }
    } catch (error) {
      console.log('catch', error);
    }
  }

  async function getItems() {
    try {
      setLoading(true);
      let res = await getItemsApi(user?._id);
      if (res.data.type === 'success') {
        setMyItems(res.data.data);
      } else {
        console.log('fails', res.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('catch', error);
    }
  }

  async function getCartItems() {
    try {
      setLoading(true);
      let res = await getUserCartApi(user?._id);
      if (res.data.type === 'success') {
        setCartData(res.data.data);
      } else {
        console.log('get user cart fails', res.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('get user cart catch', error);
    }
  }

  async function getProducts() {
    try {
      setLoading(true);
      let res = await getEcomProductsApi(user?._id);
      if (res.data.type === 'success') {
        setProducts(res.data.data);
      } else {
        console.log('get ecom products fails', res.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('get ecom products catch', error);
    }
  }

  async function getOrders() {
    try {
      setLoading(true);
      let res = await getOrdersApi(user?._id);
      if (res.data.type === 'success') {
        setOrders(res.data.data);
      } else {
        console.log('get orders api fails', res.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('get orders api catch', error);
    }
  }

  const memoizedValue = useMemo(
    () => ({
      chatList,
      chatListRef,
      setChatList,
      categories,
      setCategories,
      myItems,
      setMyItems,
      cartData,
      setCartData,
      products,
      setProducts,
      orders,
      setOrders,
      loading,
      setLoading,
    }),
    [
      chatList,
      chatListRef,
      setChatList,
      categories,
      setCategories,
      myItems,
      setMyItems,
      cartData,
      setCartData,
      products,
      setProducts,
      orders,
      setOrders,
      loading,
      setLoading,
    ]
  );

  return (
    <UserDataContext.Provider value={memoizedValue}>{props?.children}</UserDataContext.Provider>
  );
};

export default UserDataProvider;

export const useUserData = () => {
  const context = useContext(UserDataContext);

  if (context === undefined) {
    throw new Error('useUserData must be used within UserDataProvider');
  }
  return context;
};
