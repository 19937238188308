import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import Pusher from 'pusher-js';
import Button from 'components/common/button';
import { useStrings } from 'providers/stringsprovider';
import { useAuth } from 'providers/authprovider';
import { resendVerifictionLinkApi } from 'networking/api/user';
import Loader from 'components/common/loader';
import routePaths from 'routing/paths';
import styles from './styles.module.css';

const VerifyEmail = () => {
  const { strings } = useStrings();
  const { isInitialized, user, setUser } = useAuth();
  const [apiInfo, setApiInfo] = useState('');
  const [loading, setLoading] = useState(false);
  let pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, { cluster: 'eu' });

  useEffect(() => {
    let channels = [];
    if (user && !user.verified_status) {
      let channel = pusher.subscribe(`channel-${user._id}`);
      channels.push(channel);
      channel.bind('verification', (data) => {
        if (data.verified_status) {
          setUser({
            ...user,
            verified_status: true,
          });
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...user,
              verified_status: true,
            })
          );
        }
      });
    }
    return () => {
      channels.forEach((channel) => channel.unsubscribe(`channel-${user._id}`));
    };
  }, [user]);

  useEffect(() => {
    if (apiInfo) {
      setTimeout(() => {
        setApiInfo('');
      }, 5000);
    }
  }, [apiInfo]);

  async function handleResendLink() {
    try {
      setLoading(true);
      let res = await resendVerifictionLinkApi({ email: user.email });
      const { type, data, message } = res.data;
      setApiInfo(message);
      setLoading(false);
    } catch (error) {
      setApiInfo(error.message);
      setLoading(false);
    }
  }

  if (!isInitialized) {
    return <Loader />;
  }

  if (!user || (user && user.verified_status)) {
    return <Navigate to={routePaths.home} replace />;
  }

  return (
    <div className={styles.containerStyle}>
      <div className={styles.contentWrapperStyle}>
        <h2 className={styles.headerTextStyle}>
          {!user?.verified_status ? strings.verifyHeaderText : strings.cheers}
        </h2>
        <p className={styles.descTextStyle}>
          {!user?.verified_status ? strings.weHaveSentMail + ' ' + user?.email : strings.cheersDesc}
        </p>
        {!user?.verified_status && <p className={styles.descTextStyle}>{strings.verifyDesc}</p>}
        {apiInfo && <p className={styles.responseTextStyle}>{apiInfo}</p>}
        <Button
          title={!user?.verified_status ? strings.resendLink : strings.takeMeToHome}
          btnStyle={styles.btnStyle}
          onClick={handleResendLink}
        />
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default VerifyEmail;
