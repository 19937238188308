import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useUserData } from 'providers/userdataprovider';
import Image from 'components/common/image';
import {
  closeGrayIcon,
  deleteRedIcon,
  minusGrayIcon,
  plusGrayIcon,
  productSampleIcon,
} from 'resources/images';
import Button from 'components/common/button';
import styles from './styles.module.css';
import { addToCartApi } from 'networking/api/cart';
import { buyItemsApi } from 'networking/api/order';
import { useNavigate } from 'react-router';
import routePaths from 'routing/paths';

const Cart = (props) => {
  const { closeAction } = props;
  const navigate = useNavigate();
  const { cartData, setCartData, setOrders } = useUserData();
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);

  const getCartTotalPrice = () => {
    return cartData.items.reduce(
      (acc, value) => acc + value.quantity * value.product.price.selling_price,
      0
    );
  };

  const displayPrice = (val, currency = 'usd') => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(val);
  };

  async function updateQuantity(product_id, quantity) {
    try {
      setCartLoading(true);
      let res = await addToCartApi({ product_id, quantity });
      const { type, data, message } = res.data;
      if (type === 'success') {
        setCartData(data);
      }
      setCartLoading(false);
    } catch (error) {
      setCartLoading(false);
      console.log('Update quantity catch', error.message);
    }
  }

  async function handlePurchaseItems() {
    navigate(routePaths.checkout);
    // try {
    //   setPurchaseLoading(true);
    //   let res = await buyItemsApi({
    //     products: cartData.items.map((item) => ({
    //       product_id: item.product._id,
    //       quantity: item.quantity,
    //     })),
    //     currency: cartData.items[0].product.price.currency,
    //   });
    //   const { type, data, message } = res.data;
    //   if (type === 'success') {
    //     setOrders((prev) => [data, ...prev]);
    //     setCartData(null);
    //   } else {
    //     console.log(message || 'Something went wrong');
    //   }
    //   setPurchaseLoading(false);
    // } catch (error) {
    //   setPurchaseLoading(false);
    //   console.log('Update quantity catch', error.message);
    // }
  }

  const renderItems = () => {
    return (
      <div className={styles.itemsWrapperStyle}>
        {cartData.items.map((item, index) => (
          <div key={index} className={styles.eachItemViewStyle}>
            <Image
              src={item.product.images.thumbnail || productSampleIcon}
              alt="icon"
              containerStyle={styles.itemImgContainerStyle}
              imgStyle={styles.itemImgStyle}
            />
            <div className={styles.eachItemSubWrapperStyle}>
              <div className={styles.itemDetailsViewStyle}>
                <p className={styles.itemTitleTextStyle}>{item.product?.name}</p>
                <p className={styles.itemPriceTextStyle}>
                  Price ({`x${item.quantity}`}) :{' '}
                  <span>
                    {' '}
                    {displayPrice(item.product?.price.selling_price, item?.product?.price.currency)}
                  </span>
                </p>
              </div>
              <div className={styles.itemDetailsSubViewStyle}>
                <div className={styles.itemDetailsLeftSectionStyle}>
                  <Image
                    src={minusGrayIcon}
                    containerStyle={styles.countBtnsStyle}
                    imgStyle={styles.imgStyle}
                    onClick={() =>
                      item.quantity > 1 && !cartLoading
                        ? updateQuantity(item.product._id, -1)
                        : null
                    }
                  />
                  <p className={styles.countTextStyle}>{String(item.quantity).padStart(2, '0')}</p>
                  <Image
                    src={plusGrayIcon}
                    containerStyle={styles.countBtnsStyle}
                    imgStyle={styles.imgStyle}
                    onClick={() => (!cartLoading ? updateQuantity(item.product._id, 1) : null)}
                  />
                </div>
                <Image
                  src={deleteRedIcon}
                  containerStyle={styles.deleteBtnsStyle}
                  imgStyle={styles.imgStyle}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderBtnsSection = () => {
    return (
      <div className={styles.btnSectionStyle}>
        <p className={styles.btnNoteStyle}>
          To get the total price with shipping costs and other taxes, click checkout and fill
          address information.
        </p>
        <Button
          title={`Checkout - ${displayPrice(
            getCartTotalPrice(),
            cartData?.items[0]?.product.price.currency
          )}`}
          btnStyle={styles.btnStyle}
          onClick={handlePurchaseItems}
          disabled={purchaseLoading}
        />
      </div>
    );
  };

  return (
    <div className={styles.cartContainerStyle}>
      <div className={styles.cartTopSectionStyle}>
        <div className={styles.headerSectionStyle}>
          <p className={styles.headerTextStyle}>Shopping cart</p>
          <Image
            src={closeGrayIcon}
            alt="X"
            containerStyle={styles.closeIconStyle}
            onClick={closeAction}
          />
        </div>
        {cartData && cartData.items.length > 0 ? (
          <React.Fragment>
            {renderItems()}
            {renderBtnsSection()}
          </React.Fragment>
        ) : (
          <p className={styles.emptyCartTextStyle}>No cart items found</p>
        )}
      </div>
    </div>
  );
};

Cart.propTypes = {
  closeAction: PropTypes.func,
};

export default Cart;
