import React, { useState } from 'react';
import styles from './styles.module.css';
import { useStrings } from 'providers/stringsprovider';
import { chevronDownBlackIcon, chevronUpBlackIcon } from 'resources/images';
const QuestionsAnswers = () => {
  const { strings } = useStrings();

  const [toggleIndex, setToggleIndex] = useState(null);
  const setToggle = (index) => {
    if (index === toggleIndex) {
      setToggleIndex(null);
    } else {
      setToggleIndex(index);
    }
  };
  const Questions = [
    {
      id: 1,
      que: strings.homeQue1,
      ans: strings.homeQueAns1,
    },
    {
      id: 2,
      que: strings.homeQue2,
      ans: strings.homeQueAns2,
    },
    {
      id: 3,
      que: strings.homeQue3,
      ans: strings.homeQueAns3,
    },
    {
      id: 4,
      que: strings.homeQue4,
      ans: strings.homeQueAns4,
    },
  ];

  const renderQuestionsHeaderContainer = () => {
    return (
      <div className={styles.simpleHeaderContainerStyle}>
        <p className={styles.simpleHeaderTextStyle}>{strings.homeQuesHeaderTextOne}</p>
        <p className={styles.simpleHeaderTextStyle}>{strings.homeQuesHeaderTextTwo}</p>
        <p className={styles.simpleDescriptionTextStyle}>{strings.homeQuesDesc}</p>
      </div>
    );
  };
  const renderQuestionsContainer = () => {
    return (
      <div>
        <div className={styles.questionsContainerStyle}>
          {Questions.map((item, index) => {
            return (
              <div key={index} className={styles.questionWrapperStyle}>
                <div className={styles.questionTextwrapperStyle}>
                  <p className={styles.questionTextStyle}>{item.que}</p>
                  <div className={styles.arrowImgWrapperStyle} onClick={() => setToggle(index)}>
                    {toggleIndex === index ? (
                      <img src={chevronUpBlackIcon} className={styles.imgStyle} />
                    ) : (
                      <img src={chevronDownBlackIcon} className={styles.imgStyle} />
                    )}
                  </div>
                </div>
                {toggleIndex === index && <p className={styles.answerTextStyle}>{item.ans}</p>}
              </div>
            );
          })}
        </div>
        <a className={styles.letsTalkWrapperStyle}>
          <p className={styles.letsTalkTextStyle}>{strings.stillHaveQuestion}</p>
          <p className={styles.browseAllProductsStyle}>{strings.letsTalk}</p>
        </a>
      </div>
    );
  };
  return (
    <div className={styles.containerStyle}>
      {renderQuestionsHeaderContainer()}
      {renderQuestionsContainer()}
    </div>
  );
};

export default QuestionsAnswers;
