import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'providers/authprovider';
import Loader from 'components/common/loader';
import routePaths from 'routing/paths';

const AuthGuard = (props) => {
  const { children } = props;
  const { isInitialized, user } = useAuth();
  let loginRoute = 'login';

  if (!isInitialized) {
    return <Loader />;
  }

  if (!user) {
    return <Navigate to={loginRoute} />;
  }

  if (!user?.verified_status) {
    return <Navigate to={routePaths.emailVerification} replace />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
