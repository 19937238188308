import React from 'react';
import styles from './styles.module.css';
import { useStrings } from 'providers/stringsprovider';
import Image from 'components/common/image';
import {
  aboutBannerIcon,
  appleStoreIcon,
  googlePlayStoreIcon,
  checkWhiteIcon,
  cartPinkIcon,
  plusPinkIcon,
  messagePinkIcon,
  lockPinkIcon,
  clockPinkIcon,
  heartPinkIcon,
} from 'resources/images';
import NavBar from 'components/navbar';

const About = () => {
  const { strings } = useStrings();
  const colomnData = [
    {
      header: strings.hoursHeaderText,
      desc: strings.hoursDescText,
    },
    {
      header: strings.percentageHeaderText,
      desc: strings.percentageDescText,
    },
    {
      header: strings.averageHeaderText,
      desc: strings.averageDescText,
    },
    {
      header: strings.priceHeaderText,
      desc: strings.priceDescText,
    },
  ];
  const planData = [
    {
      name: 'Starter plan',
      price: '$0',
      time: '/lifetime',
      list1: 'Limit maxes at 1 item',
      list2: 'Lifetime validity',
    },
    {
      name: 'Starter plan',
      price: '$0',
      time: '/lifetime',
      list1: 'Limit maxes at 1 item',
      list2: 'Lifetime validity',
    },
    {
      name: 'Starter plan',
      price: '$0',
      time: '/lifetime',
      list1: 'Limit maxes at 1 item',
      list2: 'Lifetime validity',
    },
    {
      name: 'Starter plan',
      price: '$0',
      time: '/lifetime',
      list1: 'Limit maxes at 1 item',
      list2: 'Lifetime validity',
    },
    {
      name: 'Starter plan',
      price: '$0',
      time: '/lifetime',
      list1: 'Limit maxes at 1 item',
      list2: 'Lifetime validity',
    },
    {
      name: 'Starter plan',
      price: '$0',
      time: '/lifetime',
      list1: 'Limit maxes at 1 item',
      list2: 'Lifetime validity',
    },
  ];
  const problemsData = [
    {
      icon: cartPinkIcon,
      text: 'Vast collection of products for registering belongings',
    },
    {
      icon: plusPinkIcon,
      text: 'Easy setup and item registration process',
    },
    {
      icon: messagePinkIcon,
      text: 'Messenger functions without having an app',
    },
    {
      icon: lockPinkIcon,
      text: 'Increased privacy for both users and finders',
    },
    {
      icon: clockPinkIcon,
      text: 'Life-long use, no battery or charging required',
    },
    {
      icon: heartPinkIcon,
      text: 'Sustainable solution + Reliable everywhere and every time',
    },
  ];
  const renderBannerSection = () => {
    return (
      <div className={styles.bannerSectionStyle}>
        <div className={styles.bannerTextWrapperStyle}>
          <h1 className={styles.headerTextStyle}>{strings.aboutBannerHeaderText}</h1>
          <p className={styles.descTextStyle}>{strings.aboutBannerDesc}</p>
        </div>
        <Image src={aboutBannerIcon} />
        <h2 className={styles.subHeaderTextStyle}>
          {strings.aboutSubHeaderText}&nbsp;
          <span className={styles.subHeaderBoldTextStyle}>{strings.aboutSubHeaderTextBold}</span>
          &nbsp;{strings.aboutSubHeaderTextTwo}
        </h2>
        <div className={styles.bannerListItemsWrapperStyle}>
          {colomnData.map((item, index) => {
            return (
              <div key={index} className={styles.listItemWrapperStyle}>
                <h2 className={styles.itemHeaderTextStyle}>{item.header}</h2>
                <p className={styles.itemDescTextStyle}>{item.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const renderPlayStoreBtnSection = () => {
    return (
      <div className={styles.appStoreWrapperStyle}>
        <Image src={appleStoreIcon} />
        <Image src={googlePlayStoreIcon} />
      </div>
    );
  };
  const renderSubscriptionSection = () => {
    return (
      <div className={styles.subscriptionSectionStyle}>
        <div className={styles.subscriptionTextWrapperStyle}>
          <h1 className={styles.subscriptionHeaderStyle}>{strings.aboutSubscriptionText}</h1>
          <p className={styles.subscriptionDescStyle}>{strings.aboutSubscriptionDesc}</p>
        </div>
        <div className={styles.subscriptionCardsWrapperStyle}>
          {planData.map((item, index) => {
            return (
              <div
                key={index}
                style={{ backgroundColor: index === 0 ? '#03E38B1A' : '#1373FB1A' }}
                className={styles.cardWrapperStyle}
              >
                <div className={styles.tagsPlanWrapperStyle}>
                  <p className={styles.planTextStyle}>{item.name}</p>
                  <p className={styles.planPriceTextStyle}>
                    {item.price}
                    <span className={styles.planTimeTextStyle}>{item.time}</span>
                  </p>
                </div>
                <div className={styles.checkListWrapperStyle}>
                  <div className={styles.itemsWrapperStyle}>
                    <div
                      className={styles.checkIconWrapperStyle}
                      style={{ backgroundColor: index === 0 ? '#03E38B' : '#1373FB' }}
                    >
                      <Image src={checkWhiteIcon} containerStyle={styles.checkIconStyle} />
                    </div>
                    <p className={styles.textStyle}>{item.list1}</p>
                  </div>
                  <div className={styles.itemsWrapperStyle}>
                    <div
                      className={styles.checkIconWrapperStyle}
                      style={{ backgroundColor: index === 0 ? '#03E38B' : '#1373FB' }}
                    >
                      <Image src={checkWhiteIcon} containerStyle={styles.checkIconStyle} />
                    </div>
                    <p className={styles.textStyle}>{item.list2}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const renderEliminatingSection = () => {
    return (
      <div className={styles.subscriptionSectionStyle}>
        <div className={styles.subscriptionTextWrapperStyle}>
          <h1 className={styles.subscriptionHeaderStyle}>{strings.aboutLastSectionText}</h1>
          <p className={styles.subscriptionDescStyle}>{strings.aboutLasrSectionDesc}</p>
        </div>
        <div className={styles.subscriptionCardsWrapperStyle}>
          {problemsData.map((item, index) => {
            return (
              <div key={index} className={styles.pinkCardWrapperStyle}>
                <Image src={item.icon} containerStyle={styles.pinkIconWrapperstyle} />
                <p className={styles.descTextStyle}>{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div className={styles.containerStyle}>
      <NavBar />
      <div className={styles.subContainerStyle}>
        {renderBannerSection()}
        {renderPlayStoreBtnSection()}
        {renderSubscriptionSection()}
        {renderEliminatingSection()}
      </div>
    </div>
  );
};

export default About;
