import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Button from 'components/common/button';
import Avatar from 'components/common/avatar';
import Modal from 'components/common/modal';
import Image from 'components/common/image';
import { Rating } from 'react-simple-star-rating';
import { HiStar } from 'react-icons/hi';
import { checkWhiteIcon, clockGrayIcon, closeGrayIcon } from 'resources/images';
import Input from 'components/common/input';
import { useStrings } from 'providers/stringsprovider';
import { useUserData } from 'providers/userdataprovider';
import styles from './styles.module.css';

const MyPurchases = () => {
  const { strings } = useStrings();
  const { orders } = useUserData();
  const [showTrackOrderModal, setShowTrackOrderModal] = useState(false);
  const [showOrderReviewModal, setShowOrderReviewModal] = useState(false);

  const Status = [
    {
      title: strings.pickUpText,
      time: 'at 9:10 pm, 04-05-2022 - Place name here',
      arrived: true,
    },
    {
      title: strings.journeyStart,
      time: 'at 11:15 am, Today - Place name here',
      arrived: true,
    },
    {
      title: strings.nearestWareHouse,
      time: 'Waiting for update',
      arrived: false,
    },
    {
      title: strings.outForDelivery,
      time: 'Waiting for update',
      arrived: false,
    },
    {
      title: strings.deliveried,
      time: 'Approx: 09:00 pm, Tomorrow',
      arrived: false,
    },
  ];
  const fillColorArray = ['#FFC700'];

  const customIcons = [
    {
      icon: <HiStar size={24} />,
    },
    {
      icon: <HiStar size={24} />,
    },
    {
      icon: <HiStar size={24} />,
    },
    {
      icon: <HiStar size={24} />,
    },
    {
      icon: <HiStar size={24} />,
    },
  ];

  useEffect(() => {
    if (showTrackOrderModal || showOrderReviewModal) {
      document.documentElement.style.overflow = 'hidden';
      document.body.scroll = 'no';
    } else {
      document.documentElement.style.overflow = 'scroll';
      document.body.scroll = 'yes';
    }
  }, [showTrackOrderModal, showOrderReviewModal]);

  const displayPrice = (val, currency = 'usd') => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(val);
  };

  const handleTrackModalCloseClick = () => {
    setShowTrackOrderModal(!showTrackOrderModal);
  };

  const handleReviewModalCloseClick = () => {
    setShowOrderReviewModal(!showOrderReviewModal);
  };

  const renderReviewOrderModal = () => {
    return (
      <Modal
        show={showOrderReviewModal}
        onClose={handleReviewModalCloseClick}
        containerStyle={styles.orderReviewModalStyle}
        overlayStyle={styles.overlayStyle}
        showOverlay={true}
      >
        <div className={styles.reviewModalWrapperStyle}>
          <h2 className={styles.reviewHeaderTextStyle}>{strings.reviewText}</h2>
          <Rating
            customIcons={customIcons}
            initialValue={0}
            transition
            allowFraction
            readonly={true}
            fillColorArray={fillColorArray}
          />
          <Input
            type="textarea"
            placeholder={strings.typeComment}
            numberOfRows={4}
            onChange={(e) => console.log(e)}
          />
        </div>
        <div className={styles.reviewBtnsWrapperStyle}>
          <Button
            title={strings.cancel}
            variant="gray"
            btnStyle={styles.cancelReviewBtnStyle}
            onClick={() => setShowOrderReviewModal(false)}
          />
          <Button
            title={strings.submit}
            disabled={true}
            btnStyle={styles.submitBtnStyle}
            onClick={() => {}}
          />
        </div>
      </Modal>
    );
  };

  const renderTrackOrderModal = () => {
    return (
      <Modal
        show={showTrackOrderModal}
        onClose={handleTrackModalCloseClick}
        containerStyle={styles.orderTrackModalStyle}
        overlayStyle={styles.overlayStyle}
        showOverlay={true}
      >
        <div className={styles.modalHeaderWrapperStyle}>
          <p className={styles.modalHeaderTextStyle}>{strings.trackOrder}</p>
          <Image src={closeGrayIcon} onClick={() => handleTrackModalCloseClick()} />
        </div>
        <p className={styles.deliveryDateTextStyle}>
          You order is estimated to be delivered by tomorrow 9 PM
        </p>
        {Status.map((item, index) => {
          return (
            <div className={styles.statusStepsWrapperStyle} key={index}>
              <div
                className={styles.iconWrapperStyle}
                style={{ backgroundColor: !item.arrived && '#C4C4C4' }}
              >
                <Image src={checkWhiteIcon} containerStyle={styles.iconStyle} />
              </div>
              <div className={styles.stepsTextWrapperStyle}>
                <p
                  className={styles.stepsHeaderTextStyle}
                  style={{ color: !item.arrived && '#505050' }}
                >
                  {item.title}
                </p>
                <div className={styles.timeZoneWrapperStyle}>
                  {!item.arrived && <Image src={clockGrayIcon} />}
                  <p className={styles.stepsTimeTextStyle}>{item.time}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Modal>
    );
  };

  const renderProduct = (data, item) => {
    return (
      <div className={styles.productContainerStyle}>
        <div className={styles.productDesktopViewStyle}>
          <Avatar
            src={data.image}
            label={data.name}
            imgStyle={styles.imgStyle}
            containerStyle={styles.avatarContainerStyle}
          />
          <div className={styles.productDetailsWrapperStyle}>
            <div className={styles.detailsWrapperStyle}>
              <p className={styles.productNameStyle}>{data.name}</p>
              <p className={styles.productPriceStyle}>
                {strings.price.replace('{quantity}', data.quantity || 1)}
                <span className={styles.priceTextStyle}>
                  {displayPrice(data.price, item.currency)}
                </span>
              </p>
            </div>
            <Button
              title={item.order_status === 'Delivered' ? strings.editReview : strings.writeReview}
              btnStyle={
                item.order_status === 'Order cancelled'
                  ? styles.btnDskDisabledStyle
                  : styles.btnDesktopStyle
              }
              variant={item.order_status === 'Order cancelled' ? 'light' : 'gray'}
              disabled={item.order_status === 'Order cancelled' && true}
              size={'sm'}
              onClick={() => setShowOrderReviewModal(!showOrderReviewModal)}
            />
          </div>
        </div>
        <Button
          title={item.order_status === 'Delivered' ? strings.editReview : strings.writeReview}
          btnStyle={
            item.order_status === 'Order cancelled'
              ? styles.btnMblDisabledStyle
              : styles.btnMobileStyle
          }
          variant={item.order_status === 'Order cancelled' ? 'light' : 'gray'}
          disabled={item.order_status === 'Order cancelled' && true}
          size={'sm'}
          onClick={() => setShowOrderReviewModal(!showOrderReviewModal)}
        />
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      <h1 className={styles.headerTextStyle}>{strings.myPurchases}</h1>
      <div className={styles.ordersListStyle}>
        {orders.map((item, index) => {
          return (
            <div key={index} className={styles.orderWrapperStyle}>
              <div className={styles.orderDetailsWrapperStyle}>
                <div className={styles.orderStatusWrapperStyle}>
                  <p className={styles.orderNoTextStyle}>
                    {strings.orderNo}
                    {item.uid}
                  </p>
                  <div className={styles.orderDateWrapperStyle}>
                    <p className={styles.orderDateTextStyle}>
                      {strings.orderedOn}: {moment(item.created_at).format('DD-MM-YYYY')}
                    </p>
                    {item?.estimated_delivery_at && (
                      <p className={styles.orderStatusTextStyle}>
                        {strings.estimatedDeliveryText}:
                        <span className={styles.statusColorTextStyle}>
                          {moment(item.estimated_delivery_at).format('DD-MM-YYYY')}
                        </span>
                      </p>
                    )}
                    {item?.order_status && (
                      <p className={styles.orderStatusTextStyle}>
                        {strings.status}:&nbsp;
                        <span
                          style={{ color: item.status === 'Cancelled' && '#FA1363' }}
                          className={styles.statusColorTextStyle}
                        >
                          {item.order_status}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.orderPriceWrapperStyle}>
                  <p className={styles.orderPriceTextStyle}>
                    {displayPrice(item.total, item.currency)}
                  </p>
                  <p className={styles.orderInvoiceTextStyle}> {strings.invoice}</p>
                </div>
              </div>
              <div className={styles.productsListWrapperStyle}>
                {item.items.map((i, index) => {
                  return <div key={index}>{renderProduct(i, item)}</div>;
                })}
              </div>
              {item?.order_status && item.order_status !== 'Delivered' && (
                <div className={styles.btnsWrapperStyle}>
                  <Button
                    title={strings.cancelOrder}
                    variant="light"
                    btnStyle={styles.cancelBtnStyle}
                  />
                  <Button
                    title={strings.trackOrder}
                    btnStyle={styles.trackBtnStyle}
                    onClick={() => setShowTrackOrderModal(true)}
                  />
                </div>
              )}
              {item.status === 'Delivered' && (
                <div className={styles.btnsWrapperStyle}>
                  <Button
                    title={strings.buyAgain}
                    variant="light"
                    btnStyle={styles.cancelBtnStyle}
                  />
                </div>
              )}
              {item.status === 'Order cancelled' && (
                <div className={styles.btnsWrapperStyle}>
                  <Button
                    title={strings.orderAgain}
                    variant="light"
                    btnStyle={styles.cancelBtnStyle}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
      {renderTrackOrderModal()}
      {renderReviewOrderModal()}
    </div>
  );
};

export default MyPurchases;
