import axios from '../../utils/axios';
import * as endPoints from '../endpoints';

export const getChatListApi = async (user_id) =>
  Promise.resolve(axios.get(endPoints.getChatList.replace('{user_id}', user_id)));

export const getChatInfoByProductApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getChatInfo.replace('{id}', id)));

export const getChatMessagesApi = async (chat_id) =>
  Promise.resolve(axios.get(endPoints.getChatMessages.replace('{chat_id}', chat_id)));

export const sendChatMessageApi = async (data) =>
  Promise.resolve(axios.post(endPoints.sendChatMessage, data));

export const updateMessageReadStatusApi = async (data) =>
  Promise.resolve(axios.put(endPoints.updateMessageReadStatus, data));
