import React, { createContext, useMemo, useEffect, useContext } from 'react';
import { io } from 'socket.io-client';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import { getItemDetailsBySnoOrIdApi } from 'networking/api/myItems';

export const socket = io(process.env.REACT_APP_SERVER_URL, {
  transports: ['websocket'],
});

const SocketContext = createContext();

const SocketProvider = (props) => {
  const { user, isLoggedIn } = useAuth();
  const { chatList, chatListRef, setChatList } = useUserData();

  useEffect(() => {
    chatListRef.current = chatList;
  }, [chatList]);

  useEffect(() => {
    if (isLoggedIn && user) {
      if (!socket.connected) {
        socket.connect();
        socket.emit('join_socket', user._id);
      }
    }

    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
    // eslint-disable-next-line
  }, [user, isLoggedIn]);

  useEffect(() => {
    socketListner();
  }, []);

  const socketListner = () => {
    socket.on('new_chat_received', async (newChat) => {
      let modifyingChatList = [...chatListRef.current];
      let existChatIndex = modifyingChatList.findIndex(
        (chat) => chat._id === newChat.chat_id || chat.product_id._id === newChat.product_id
      );
      if (existChatIndex !== -1) {
        modifyingChatList[existChatIndex] = {
          ...modifyingChatList[existChatIndex],
          last_message: newChat.last_message,
          updated_at: newChat.updated_at,
          count: modifyingChatList[existChatIndex].count + 1,
        };
        const movedObject = modifyingChatList.splice(existChatIndex, 1)[0];
        modifyingChatList.unshift(movedObject);
      } else {
        let productResponse = await getItemDetailsBySnoOrIdApi({
          product_id: newChat.product_id,
        });
        modifyingChatList = [
          {
            _id: newChat.chat_id,
            count: 1,
            created_at: newChat.created_at,
            owner_id: productResponse.data.data.owner_id,
            updated_at: newChat.updated_at,
            last_message: newChat.last_message,
            product_id: productResponse.data.data,
          },
          ...modifyingChatList,
        ];
      }
      chatListRef.current = modifyingChatList;
      setChatList(modifyingChatList);
    });
  };

  const sendSocketMessage = (data, product_id) => {
    socket.emit('new_message', { data, product_id });
  };

  const memoizedValue = useMemo(
    () => ({
      socket,
      sendSocketMessage,
    }),
    [socket, sendSocketMessage]
  );

  return <SocketContext.Provider value={memoizedValue}>{props.children}</SocketContext.Provider>;
};

export default SocketProvider;

export const useSocket = () => {
  const context = useContext(SocketContext);

  if (context === undefined) {
    throw new Error('useSocket must be used within SocketProvider');
  }
  return context;
};
