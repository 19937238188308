import React from 'react';
import Button from 'components/common/button';
import Image from 'components/common/image';
import { deleteBlackIcon } from 'resources/images';
import { useStrings } from 'providers/stringsprovider';
import { useUserData } from 'providers/userdataprovider';
import moment from 'moment';
import { useNavigate } from 'react-router';
import routePaths from 'routing/paths';
import styles from './styles.module.css';

const Notifications = () => {
  const navigate = useNavigate();
  const { strings } = useStrings();
  const { chatList } = useUserData();

  const msgCount = chatList.reduce((acc, val) => acc + val.count, 0);
  const chatCount = chatList.reduce((acc, val) => (acc + val.count > 0 ? 1 : 0), 0);
  return (
    <div className={styles.containerStyle}>
      <div className={styles.headerWrapperStyle}>
        <h2 className={styles.headerTextStyle}>{strings.notifications}</h2>
      </div>
      <div className={styles.notificationScrollStyle}>
        {chatCount || msgCount ? (
          <div className={styles.notificationWrapperStyle}>
            <div className={styles.messageWrapperStyle}>
              <ul className={styles.listStyle}>
                <li className={styles.itemStyle}>Today, {moment().format('HH:mm')}</li>
              </ul>
              <p className={styles.boldTextStyle}>
                {strings.messageAlert} &nbsp;
                <span className={styles.normalTextStyle}>
                  You have {msgCount} new messages from {chatCount} chats.
                </span>
              </p>
            </div>
            <div className={styles.btnsWrapperStyle}>
              <Button
                title={strings.openChat}
                btnStyle={styles.buttonStyle}
                onClick={() => navigate(routePaths.chat)}
              />
              {/* <Image src={deleteBlackIcon} containerStyle={styles.iconStyle} /> */}
            </div>
          </div>
        ) : (
          <div className={styles.notificationWrapperStyle}>
            <p className={styles.normalTextStyle}>Currently you don't have any notifications</p>
          </div>
        )}
      </div>
      {/* <div className={styles.emptyListWrapperStyle}>
        <p className={styles.boldEmptyTextStyle}>
          {strings.zeroNotifications}&nbsp;
          <span className={styles.normalEmptyTextStyle}>
          {strings.zeroNotifiMessage}
          </span>
        </p>
      </div> */}
    </div>
  );
};

export default Notifications;
