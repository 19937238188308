import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import { uploadImageApi } from 'networking/api/user';
import {
  getChatInfoByProductApi,
  getChatMessagesApi,
  sendChatMessageApi,
  updateMessageReadStatusApi,
} from 'networking/api/chat';
import {
  arrowDownFillGrayIcon,
  chatGrayIcon,
  checkBlueIcon,
  checkBoxSqareBlueIcon,
  checkBoxSqareGrayIcon,
  chevronLeftBlackIcon,
  downloadBlueIcon,
  downloadGrayIcon,
  galleryGrayIcon,
  sendBlueIcon,
  sendGrayIcon,
  verticalMenuGrayIcon,
} from 'resources/images';
import Button from 'components/common/button';
import Image from 'components/common/image';
import Input from 'components/common/input';
import Capsule from 'components/common/capsule';
import Loader from 'components/common/loader';
import { getItemIcon } from 'functions';
import PopOver from 'components/common/popover';
import useWindowDimensions from 'hooks/useWindowDimensions';
import styles from './styles.module.css';
import { getItemDetailsBySnoOrIdApi } from 'networking/api/myItems';
import { useSocket } from 'providers/socketprovider';

const Chat = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const sno = searchParams.get('selected');
  const { socket } = useSocket();
  const { width } = useWindowDimensions();
  const { user } = useAuth();
  const { chatList, chatListRef, setChatList } = useUserData();
  const [filterModalRef, setFilterModalRef] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedChatFilter, setSelectedChatFilter] = useState('All chats');
  const [chats, setChats] = useState(chatList);
  const [selectedChat, setSelectedChat] = useState(null);
  const [showChatOptionsModal, setShowOptionsModal] = useState(false);
  const [chatOptionsRef, setChatOptionsRef] = useState(null);
  const [selectedChatOption, setSelectedChatOption] = useState('');
  const [text, setText] = useState('');
  const [image, setImage] = useState('');
  const [sendMsgLoading, setSendMsgLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesLoader, setMessagesLoader] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef();
  const messagesEndRef = useRef();
  const guestEmail = localStorage.getItem('guest_email');

  const chatFilters = ['All chats', 'My items', 'Found items'];
  const chatOptions = [
    {
      title: 'Transfer ownership',
      img: { active: checkBoxSqareBlueIcon, normal: checkBoxSqareGrayIcon },
      onClick: (item) => {
        setSelectedChatOption(item.title);
      },
    },
    {
      title: 'Archive chat',
      img: { active: downloadBlueIcon, normal: downloadGrayIcon },
      onClick: (item) => {
        setSelectedChatOption(item.title);
      },
    },
  ];

  useEffect(() => {
    socketListner();
  }, []);

  const handleSelectChat = (chat) => {
    setSearchParams({ selected: chat.product_id.sno });
    socket.emit('join_room', chat.product_id._id);
  };

  const handleChatGoBack = () => {
    socket.emit('leave_room', selectedChat?.product_id._id);
    navigate(-1);
  };

  const socketListner = async () => {
    socket.on('new_message_received', (newMessage) => {
      setMessages((prevMessages) => {
        let prev = [...prevMessages];
        let checkIndex = prev.findIndex((el) => el._id === newMessage.data._id);
        if (checkIndex === -1) {
          prev = [...prev, newMessage.data];
        }
        return prev;
      });
      let modifyingChatList = [...chatListRef.current];
      let existChatIndex = modifyingChatList.findIndex(
        (chat) =>
          chat._id === newMessage.data.chat_id || chat.product_id._id === newMessage.product_id
      );
      if (existChatIndex !== -1) {
        modifyingChatList[existChatIndex] = {
          ...modifyingChatList[existChatIndex],
          last_message: newMessage.data.text,
          updated_at: newMessage.data.updated_at,
          count: modifyingChatList[existChatIndex].count + 1,
        };
        const movedObject = modifyingChatList.splice(existChatIndex, 1)[0];
        modifyingChatList.unshift(movedObject);
      }
      chatListRef.current = modifyingChatList;
      if (newMessage.data.chat_id) {
        readStatus(newMessage.data.chat_id, user?._id, newMessage.data.text);
      }
    });
  };

  useEffect(() => {
    if (selectedChatFilter === 'All chats') {
      setChats(chatList);
    } else if (selectedChatFilter === 'My items') {
      setChats([...chatList].filter((item) => item.owner_id === user?._id));
    } else if (selectedChatFilter === 'Found items') {
      setChats([...chatList].filter((item) => item.owner_id !== user?._id));
    } else {
      setChats(chatList);
    }
  }, [chatList, selectedChatFilter, user]);

  useEffect(() => {
    if (selectedChat) {
      getSelectedChatMessages();
      socket.emit('join_room', selectedChat.product_id._id);
    }
    return () => {
      socket.emit('leave_room', selectedChat?.product_id._id);
    };
    // eslint-disable-next-line
  }, [selectedChat]);

  useEffect(() => {
    async function isChatExists() {
      if (sno) {
        let chatExists = chats.find((item) => item.product_id.sno === sno);
        if (chatExists) {
          if (!selectedChat) {
            setSelectedChat(chatExists);
          } else {
            if (selectedChat._id !== chatExists._id) {
              setSelectedChat(chatExists);
            }
          }
        } else {
          let res = await getItemDetailsBySnoOrIdApi({ sno });
          if (res.data.type === 'success') {
            let chatRes = await getChatInfoByProductApi(res.data.data._id);
            if (chatRes.data.type === 'success') {
              setChatList((prev) => {
                let previous_data = [...prev];
                let index = previous_data.findIndex((i) => i.product_id.sno === sno);
                if (index !== -1) {
                  previous_data[index]._id = chatRes.data._id;
                } else {
                  previous_data = [chatRes.data.data, ...prev];
                }
                return previous_data;
              });
              setSelectedChat(chatRes.data.data);
            } else {
              let newChat = {
                _id: Math.random(),
                owner_id: res.data.data.owner_id,
                product_id: res.data.data,
                created_at: null,
                updated_at: null,
                count: 0,
              };
              setChatList((prev) => [newChat, ...prev]);
              setSelectedChat(newChat);
            }
          }
        }
      } else {
        setSelectedChat(null);
      }
    }
    isChatExists();
  }, [chats, sno]);

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  async function getSelectedChatMessages() {
    try {
      setMessagesLoader(true);
      let res = await getChatMessagesApi(selectedChat._id);
      if (res.data.type === 'success') {
        setMessages(res.data.data);
        setMessagesLoader(false);
        readStatus(selectedChat._id, user?._id);
        scrollToBottom();
      } else {
        setMessagesLoader(false);
        console.log('get Chat Messages Api fails', res.message);
      }
    } catch (error) {
      setMessagesLoader(false);
      console.log('get Chat Messages catch', error.message);
    }
  }

  async function sendMessage(img) {
    try {
      if (text || image || img) {
        const data = {
          product_id: selectedChat.product_id._id,
          owner_id: selectedChat.product_id.owner_id,
          finder_id: selectedChat.product_id.owner_id === user?._id ? '' : user?._id,
          finder_email: user?._id ? '' : guestEmail ? guestEmail : '',
          receiver_id:
            selectedChat.product_id.owner_id === user?._id ? '' : selectedChat.product_id.owner_id,
          text,
          image: image || img,
          created_at: new Date(),
          updated_at: new Date(),
        };
        setSendMsgLoading(true);
        let res = await sendChatMessageApi(data);
        if (res.data.type === 'success') {
          // setMessages((prev) => [...prev, res.data.data]);
          socket.emit('new_message', {
            data: res.data.data,
            product_id: selectedChat.product_id._id,
          });
          setSendMsgLoading(false);
        } else {
          setSendMsgLoading(false);
          setError(res.data.message);
          console.log('Send chat message api fails', res.data.message);
        }
        setText('');
        setImage('');
      }
    } catch (error) {
      setSendMsgLoading(false);
      console.log('Send chat message api catch');
    }
  }

  async function readStatus(chat_id, login_id = '', last_message = '') {
    let receiver_id = '';
    if (selectedChat?.product_id.owner_id === login_id) {
      receiver_id = selectedChat?.product_id.owner_id;
    }
    try {
      let params = {
        chat_id: chat_id,
        receiver_id: receiver_id,
      };
      let updateReadStatusResponse = await updateMessageReadStatusApi(params);
      let newChatListIndex = chatListRef.current.findIndex((obj) => obj._id === chat_id);
      let newChatListData = [...chatListRef.current];
      newChatListData[newChatListIndex].count = 0;
      if (last_message) {
        newChatListData[newChatListIndex].last_message = last_message;
      }
      chatListRef.current = newChatListData;
    } catch (error) {
      console.log('updateReadStatus catch', error);
    }
  }

  async function handleImageUploadSelection(e) {
    try {
      const file = e.target.files[0];
      let data = new FormData();
      data.append('image', file);
      let res = await uploadImageApi(data);
      if (res.data.type === 'success') {
        setImage(res.data.data);
        await sendMessage(res.data.data);
      } else {
        console.log('Upload image Api fails', res.data.message);
      }
      fileInputRef.current.value = '';
    } catch (error) {
      console.log('Upload image api catch');
    }
  }

  function scrollToBottom(property = 'auto') {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behaviour: property });
    }
  }

  let previousTime = '';
  const shouldDisplayTime = (message, index) => {
    if (index > 0) {
      if (previousTime === moment(message.created_at).format('dddd, MMM DD')) {
        return false;
      } else {
        previousTime = moment(message.created_at).format('dddd, MMM DD');
        return true;
      }
    } else {
      previousTime = moment(message.created_at).format('dddd, MMM DD');
      return true;
    }
  };

  const renderEmptyChatsContainer = () => {
    return (
      <div className={styles.emptyChatsContainerStyle}>
        <Image src={chatGrayIcon} />
        <p className={styles.emptyContainerTextStyle}>No chats available at this moment</p>
      </div>
    );
  };

  const renderEmptyChatListContainer = () => {
    return (
      <div className={styles.emptyChatsContainerStyle}>
        <p className={styles.emptyContainerTextStyle}>No chats available</p>
      </div>
    );
  };

  const renderNoProductContainer = () => {
    return (
      <div className={styles.emptyChatsContainerStyle}>
        <p className={styles.emptyContainerTextStyle}>No product found</p>
      </div>
    );
  };

  const renderEmptyMessagesContainer = () => {
    return (
      <div className={styles.emptyMessagesContainerStyle}>
        <p className={styles.emptyContainerTextStyle}>
          No conversation in this chat. Type below and hit send to start conversing
        </p>
      </div>
    );
  };

  const renderEmptySelectedChatContainer = () => {
    return (
      <div className={styles.emptyChatsContainerStyle}>
        <Image src={chatGrayIcon} />
        <p className={styles.emptyContainerTextStyle}>Select a chat to start messaging</p>
      </div>
    );
  };

  const renderChatFilterModal = () => {
    return (
      <PopOver
        show={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        reference={filterModalRef}
        placement="bottom-end"
        containerStyle={styles.filterModalStyle}
      >
        {chatFilters.map((item, index) => (
          <div
            className={styles.chatFilterItemStyle}
            key={index}
            onClick={() => setSelectedChatFilter(item)}
          >
            <p
              className={classNames(
                styles.chatFilterItemTitleStyle,
                selectedChatFilter === item ? styles.selectedChatFilterItemTitleStyle : ''
              )}
            >
              {item}
            </p>
            {selectedChatFilter === item && (
              <Image src={checkBlueIcon} containerStyle={styles.chatFilterIconStyle} />
            )}
          </div>
        ))}
      </PopOver>
    );
  };

  const renderChatOptionsModal = () => {
    return (
      <PopOver
        show={showChatOptionsModal}
        onClose={() => {
          setShowOptionsModal(false);
          setSelectedChatOption('');
        }}
        reference={chatOptionsRef}
        placement="bottom-end"
        containerStyle={styles.chatOptionModalStyle}
      >
        {chatOptions.map((item, index) => (
          <div className={styles.chatOptionStyle} key={index} onClick={() => item.onClick(item)}>
            <Image
              src={selectedChatOption === item.title ? item.img.active : item.img.normal}
              containerStyle={styles.chatOptionIconStyle}
            />
            <p
              className={classNames(
                styles.chatOptionTextStyle,
                selectedChatOption === item.title ? styles.selectedChatOptionTextStyle : ''
              )}
            >
              {item.title}
            </p>
          </div>
        ))}
      </PopOver>
    );
  };

  const renderMessagesContainer = () => {
    return (
      <div className={styles.messageListStyle} id="scroll">
        {messages.map((message, index) => {
          return (
            <React.Fragment key={index}>
              {shouldDisplayTime(message, index) && (
                <Capsule
                  label={moment(message.created_at).format('dddd, MMM DD')}
                  containerStyle={styles.dateWrapperStyle}
                />
              )}
              <div
                className={
                  user?._id === message.receiver_id
                    ? styles.leftTextWrapperStyle
                    : styles.rightTextWrapperStyle
                }
              >
                <div className={styles.messageTextStyle}>
                  {message.image && (
                    <Image src={message.image} containerStyle={styles.messageImageStyle} />
                  )}
                  <p>{message.text}</p>
                </div>
                <span className={styles.messageTimeStyle}>
                  {moment(message.created_at).format('hh:mm')}
                </span>
              </div>
            </React.Fragment>
          );
        })}
        <div ref={messagesEndRef} />
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {chats.length === 0 ? (
        renderEmptyChatsContainer()
      ) : (
        <React.Fragment>
          <div className={styles.leftSectionStyle}>
            <div className={styles.leftSectionHeaderBlockStyle}>
              <p className={styles.leftSectionHeaderTitleStyle}>Messages</p>
              <Button
                title="All"
                variant="gray"
                rightIcon={arrowDownFillGrayIcon}
                btnStyle={styles.filterBtnStyle}
                reference={setFilterModalRef}
                onClick={() => setShowFilterModal(true)}
              />
              {renderChatFilterModal()}
            </div>
            <div className={styles.leftSectionBodyBlockStyle}>
              {chats.length === 0 && renderEmptyChatListContainer()}
              {chats.length > 0 &&
                chats.map((chat, index) => (
                  <div
                    key={index}
                    className={classNames(
                      styles.chatItemStyle,
                      chat._id === selectedChat?._id ? styles.selectedChatItemStyle : ''
                    )}
                    onClick={() => handleSelectChat(chat)}
                  >
                    <Image
                      src={getItemIcon(chat?.product_id?.category?.title)}
                      containerStyle={styles.chatItemIconStyle}
                    />
                    <div className={styles.chatItemDataBlockStyle}>
                      <div className={styles.chatItemDataWrapperStyle}>
                        <p className={styles.chatItemTitleStyle}>
                          {chat?.product_id?.product_name}
                        </p>
                        {chat?.updated_at && (
                          <p className={styles.chaItemDescStyle}>
                            {moment(chat.updated_at).format('hh:mm a')}
                          </p>
                        )}
                      </div>
                      <div className={styles.chatItemDataWrapperStyle}>
                        <p className={styles.chatItemSubTitleStyle}>
                          <span className={styles.chatItemSubDescStyle}>
                            {chat?.last_message
                              ? chat.last_message
                              : chat.image
                              ? 'Sent an image'
                              : ''}
                          </span>
                        </p>
                        {chat?.count > 0 && (
                          <p className={styles.chatItemCountStyle}>{chat.count}</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {(sno || width > 867) && (
            <div className={styles.rightSectionStyle}>
              {!sno ? (
                renderEmptySelectedChatContainer()
              ) : !selectedChat ? (
                renderNoProductContainer()
              ) : (
                <React.Fragment>
                  <div className={styles.rightSectionHeaderBlockStyle}>
                    {width <= 867 && (
                      <Image src={chevronLeftBlackIcon} onClick={handleChatGoBack} />
                    )}
                    <Image
                      src={getItemIcon(selectedChat?.product_id?.category?.title)}
                      containerStyle={styles.chatDataIconStyle}
                    />
                    <div className={styles.chatDataContentWrapperStyle}>
                      <p className={styles.chatDataContentTitleStyle}>
                        {selectedChat?.product_id?.product_name}
                      </p>
                      <p className={styles.chatDataContentDescStyle}>
                        SNO#{selectedChat?.product_id?.sno}
                      </p>
                    </div>
                    <Image
                      src={verticalMenuGrayIcon}
                      containerStyle={styles.chatDataActionIconStyle}
                      onClick={() => setShowOptionsModal(true)}
                      reference={setChatOptionsRef}
                    />
                    {renderChatOptionsModal()}
                  </div>
                  <div className={styles.messagesBodyBlockStyle}>
                    {messagesLoader ? (
                      <Loader />
                    ) : messages.length === 0 ? (
                      renderEmptyMessagesContainer()
                    ) : (
                      renderMessagesContainer()
                    )}
                  </div>
                  <div className={styles.messageInputWrapperStyle}>
                    <Input
                      placeholder={
                        selectedChat?.product_id?.conversations_state
                          ? 'Type here...'
                          : 'Conversations is disabled by the owner'
                      }
                      value={text}
                      onChange={(e) => {
                        setText(e.target.value);
                        setError('');
                      }}
                      inputStyle={styles.messageInputStyle}
                      leftIcon={galleryGrayIcon}
                      rightIcon={text.length > 0 ? sendBlueIcon : sendGrayIcon}
                      leftIconStyle={styles.inputLeftIconStyle}
                      rightIconStyle={styles.inputRightIconStyle}
                      onClickLeftIcon={!sendMsgLoading ? () => fileInputRef.current.click() : null}
                      onClickRightIcon={!sendMsgLoading ? () => sendMessage() : null}
                      hasError={error}
                      disabled={!selectedChat.product_id.conversations_state}
                    />
                    <input
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      hidden
                      ref={fileInputRef}
                      onChange={handleImageUploadSelection}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Chat;
