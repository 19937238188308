import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Image from 'components/common/image';
import { chevronRightBlackIcon, closeGrayIcon, playIcon } from 'resources/images';
import NavBar from 'components/navbar';
import Modal from 'components/common/modal';
import Input from 'components/common/input';
import { checkQrApi, getItemDetailsBySnoOrIdApi } from 'networking/api/myItems';
import { getItemIcon } from 'functions';
import Button from 'components/common/button';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useAuth } from 'providers/authprovider';
import Loader from 'components/common/loader';
import Scanner from 'components/scanner';
import AddorReturnCard from 'components/add-or-return-card';
import routePaths from 'routing/paths';
import { checkIsUserGuestApi } from 'networking/api/user';
import { Link } from 'react-router-dom';
import WorkingFlow from 'sections/working-flow';
import SustainabilitySection from 'sections/SustainabilitySection';
import SocialSection from 'sections/SocialSection';
import QuestionsAnswers from 'sections/questions-answers';
import Footer from 'components/footer';
import SampleProducts from 'sections/sample-products';
import styles from './styles.module.css';

const Home = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { width } = useWindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sno, setSno] = useState('');
  const [error, setError] = useState('');
  const [product, setProduct] = useState(null);
  const [showScanner, setShowScanner] = useState(false);
  const [scanResultModal, setScanResultModal] = useState(false);
  const [itemModal, setItemModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [guestModal, setGuestModal] = useState(false);
  const guestEmail = localStorage.getItem('guest_email');
  const [guestInputMail, setGuestInputMail] = useState('');
  const [guestError, setGuestError] = useState('');

  useEffect(() => {
    if (isModalOpen || product) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'scroll';
    }
  }, [isModalOpen, product]);

  const handleModalCloseClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const verifySno = async (id) => {
    try {
      if (id) {
        setIsLoading(true);
        let res = await checkQrApi({ qr_number: id });
        if (res.data.tag === 'not_available') {
          if (showScanner) {
            setScanResultModal(true);
          } else {
            setError('Invalid SNO.');
          }
        } else if (res.data.tag === 'used') {
          let productData = await getItemDetailsBySnoOrIdApi({ sno: id });
          if (productData.data.type === 'success') {
            setShowScanner(false);
            if (user?._id !== productData.data.data?.owner_id) {
              setItemModal(true);
              setProduct(productData.data.data);
            } else {
              setError('Sno is linked with your item so please try another');
            }
          } else {
            setError('Something went wrong');
          }
        }
        setIsLoading(false);
      } else {
        setError('Please enter sno');
      }
    } catch (error) {}
  };

  const tapOnContactOwner = (email = '') => {
    if (guestEmail || user || email) {
      navigate(routePaths.chat + `?selected=${product.sno}`);
    } else {
      setGuestModal(true);
    }
  };

  const handleScan = (url, error) => {
    if (url) {
      alert(url);
      let data = url?.split('/');
      let id = data[data.length - 1];
      if (id) {
        verifySno(id);
      }
    }
    if (error) {
      console.log('error', error.message);
    }
  };

  const checkUserGuestOrNot = async () => {
    try {
      setGuestError('');
      let res = await checkIsUserGuestApi({ email: guestInputMail });
      if (res.data.type === 'success') {
        localStorage.setItem('guest_email', guestInputMail);
        tapOnContactOwner(guestInputMail);
      } else {
        setGuestError(res.data.message);
      }
    } catch (error) {
      setGuestError(error.message);
    }
  };

  // ui section

  const renderReturnModal = () => {
    return (
      <Modal
        show={isModalOpen}
        containerStyle={styles.returnModalStyle}
        overlayStyle={styles.overlayStyle}
        onClose={handleModalCloseClick}
        showOverlay={true}
      >
        <AddorReturnCard
          title="Return item!"
          description=" Scan QR or enter SNO present on the tag attached to the found-item to contact the item
        owner."
          error={error}
          isLoading={isLoading}
          onClose={() => {
            handleModalCloseClick();
            setSno('');
            setError('');
          }}
          onChange={(e) => {
            setSno(e.target.value);
            setError('');
          }}
          onConfirmAction={() => {
            setError('');
            verifySno(sno);
          }}
          tapOnScanBtn={() => setShowScanner(true)}
        />
      </Modal>
    );
  };

  const renderItemModal = () => {
    return (
      <Modal
        show={itemModal}
        showOverlay
        overlayStyle={styles.itemOverlayStyle}
        containerStyle={styles.itemModalStyle}
        onClose={() => setProduct(null)}
        closeOnOutSideClick
      >
        <div className={styles.itemContentBlcokStyle}>
          <p className={styles.itemTitleStyle}>{product?.product_name}</p>
          <Image
            src={product?.product_image || getItemIcon()}
            containerStyle={styles.itemImageStyle}
          />
          <div className={styles.itemDataWrapperStyle}>
            <p className={styles.itemDataTitleStyle}>
              Unique code or SNO: <span className={styles.itemDataDescStyle}>{product?.sno}</span>
            </p>
            <p className={styles.itemDataTitleStyle}>
              Category: <span className={styles.itemDataDescStyle}>{product?.category.title}</span>
            </p>
            <p className={styles.itemDataTitleStyle}>
              Notes: <span className={styles.itemDataDescNoteStyle}>{product?.note}</span>
            </p>
          </div>
        </div>
        <div className={styles.btnWrapperStyle}>
          <Button
            title="Cancel"
            variant="gray"
            onClick={() => setProduct(null)}
            btnStyle={styles.btnStyle}
            size={width > 576 ? 'lg' : 'md'}
          />
          <Button
            title={'Contact owner'}
            onClick={() => tapOnContactOwner()}
            btnStyle={styles.btnStyle}
            size={width > 576 ? 'lg' : 'md'}
          />
        </div>
      </Modal>
    );
  };

  const renderScanner = () => {
    return (
      <Modal show={showScanner}>
        <Scanner
          handleScan={handleScan}
          alertModal={scanResultModal}
          onCloseScanner={() => {
            setShowScanner(false);
            setScanResultModal(false);
          }}
          onCloseRescanModal={() => setScanResultModal(false)}
        />
      </Modal>
    );
  };

  const renderGuestModal = () => {
    return (
      <Modal
        show={guestModal}
        onClose={() => setGuestModal(false)}
        closeOnOutSideClick
        showOverlay
        overlayStyle={styles.itemOverlayStyle}
        containerStyle={styles.itemModalStyle}
      >
        <div className={styles.itemContentBlcokStyle}>
          <p className={styles.guestModalTitleStyle}>Seems like you haven't loggedin</p>
          <p className={styles.guestModalDescStyle}>
            To continue, enter your email address so that we can create a guest account.
          </p>
          <Input
            placeholder="Enter your email address"
            value={guestInputMail}
            onChange={(e) => {
              setGuestInputMail(e.target.value);
              setGuestError('');
            }}
          />
          {guestError && <p className={styles.errorStyle}>{guestError}</p>}
          <Button onClick={checkUserGuestOrNot} title="Continue" />
          <div className={styles.guestAuthWrapperStyle}>
            <Link to={'/login'} className={styles.linkStyle}>
              Log in
            </Link>{' '}
            Or{' '}
            <Link to={'/signup'} className={styles.linkStyle}>
              Sign up
            </Link>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={styles.containerStyle}>
      <NavBar />
      {/* poster secction */}
      <div className={styles.posterViewStyle}>
        <div className={styles.posterSubViewStyle}>
          <div className={styles.contentWrapperStyle}>
            <div className={styles.contentSubWrapperStyle}>
              <p className={styles.titleTextStyle}>The new standard of lost & found solutions.</p>
              <div className={styles.descTextViewStyle}>
                <p className={styles.descTextStyle}>
                  Foundi is a QR code solution created to recover lost items while protecting your
                  identity and privacy.
                </p>
                <div className={styles.playViewStyle}>
                  <Image src={playIcon} />
                  <p className={styles.playTextStyle}>Watch video</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* poster secction end */}
      <div className={styles.returnSectionStyle}>
        <p className={styles.returnTextStyle}>
          👋 Have you found a lost-item?&nbsp;
          <span onClick={() => setIsModalOpen(true)}>Return it now</span>
        </p>
      </div>
      <WorkingFlow />
      <SampleProducts />
      <QuestionsAnswers />
      <SustainabilitySection />
      <SocialSection />
      <Footer />
      {renderScanner()}
      {renderReturnModal()}
      {renderItemModal()}
      {guestModal && renderGuestModal()}
    </div>
  );
};

export default Home;
