import React, { useState } from 'react';
import { chevronRightGrayIcon, infoCircleFillRedIcon } from 'resources/images';
import Input from 'components/common/input';
import { NavLink, useNavigate } from 'react-router-dom';
import Modal from 'components/common/modal';
import Button from 'components/common/button';
import Image from 'components/common/image';
import { checkQrApi, getItemDetailsBySnoOrIdApi } from 'networking/api/myItems';
import { useAuth } from 'providers/authprovider';
import Loader from 'components/common/loader';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getItemIcon } from 'functions';
import AddorEditItem from 'sections/add-or-edit-item';
import Scanner from 'components/scanner';
import routePaths from 'routing/paths';
import styles from './styles.module.css';

const AddOrReturnItem = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { width } = useWindowDimensions();
  const [sno, setSno] = useState('');
  const [error, setError] = useState('');
  const [product, setProduct] = useState(null);
  const [showScanner, setShowScanner] = useState(false);

  const [scanResultModal, setScanResultModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);

  const verifySno = async (id) => {
    try {
      if (id) {
        setIsLoading(true);
        let res = await checkQrApi({ qr_number: id });
        if (res.data.tag === 'not_available') {
          if (showScanner) {
            setScanResultModal(true);
          } else {
            setError('Invalid SNO.');
          }
          setIsLoading(false);
        } else if (res.data.tag === 'available') {
          console.log('Available');
          setSno(id);
          setIsAddItemModalOpen(true);
          setShowScanner(false);
          setIsLoading(false);
        } else if (res.data.tag === 'used') {
          let productData = await getItemDetailsBySnoOrIdApi({ sno: id });
          console.log('prodduct', productData);
          if (productData.data.type === 'success') {
            setShowScanner(false);
            setProduct(productData.data.data);
          } else {
            setError('Something went wrong');
          }
          setIsLoading(false);
        }
      } else {
        setError('Please enter sno');
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleScan = (url, error) => {
    if (url) {
      let data = url?.split('/');
      let id = data[data.length - 1];
      if (id) {
        verifySno(id);
      }
    }
    if (error) {
      console.log('error', error.message);
    }
  };

  const tapOnContactOwner = () => {
    navigate(routePaths.chat + `?selected=${product.sno}`);
  };

  const tapOnViewDetails = () => {
    navigate(routePaths.myItems + `?selected=${product.sno}`);
  };

  // ui section

  const renderItemModal = () => {
    return (
      <Modal
        show={Boolean(product)}
        showOverlay
        overlayStyle={styles.itemOverlayStyle}
        containerStyle={styles.itemModalStyle}
        onClose={() => setProduct(null)}
        closeOnOutSideClick
      >
        <div className={styles.itemContentBlcokStyle}>
          <p className={styles.subTitleStyle}>{product?.product_name}</p>
          <Image
            src={product?.product_image || getItemIcon()}
            containerStyle={styles.itemImageStyle}
          />
          <div className={styles.itemDataWrapperStyle}>
            <p className={styles.itemDataTitleStyle}>
              Unique code or SNO: <span className={styles.itemDataDescStyle}>{product?.sno}</span>
            </p>
            <p className={styles.itemDataTitleStyle}>
              Category: <span className={styles.itemDataDescStyle}>{product?.category.title}</span>
            </p>
            <p className={styles.itemDataTitleStyle}>
              Notes: <span className={styles.itemDataDescNoteStyle}>{product?.note}</span>
            </p>
          </div>
        </div>
        <div className={styles.btnWrapperStyle}>
          <Button
            title="Cancel"
            variant="gray"
            onClick={() => setProduct(null)}
            btnStyle={styles.btnStyle}
            size={width > 576 ? 'lg' : 'md'}
          />
          <Button
            title={user?._id === product?.owner_id ? 'View details' : 'Contact owner'}
            onClick={() =>
              user?._id === product?.owner_id ? tapOnViewDetails() : tapOnContactOwner()
            }
            btnStyle={styles.btnStyle}
            size={width > 576 ? 'lg' : 'md'}
          />
        </div>
      </Modal>
    );
  };

  const renderScanner = () => {
    return (
      <Modal show={showScanner}>
        <Scanner
          handleScan={handleScan}
          alertModal={scanResultModal}
          onCloseScanner={() => {
            setShowScanner(false);
            setScanResultModal(false);
          }}
          onCloseRescanModal={() => setScanResultModal(false)}
        />
        {isLoading && showScanner && <Loader containerStyle={styles.customLoaderStyle} />}
      </Modal>
    );
  };

  const renderAddItemModal = () => {
    return (
      <Modal
        show={isAddItemModalOpen}
        showOverlay
        overlayStyle={styles.addItemOverlayStyle}
        containerStyle={styles.addItemModalStyle}
        // onClose={() => setIsAddItemModalOpen(false)}
        // closeOnOutSideClick
      >
        <AddorEditItem item={{ sno: sno }} closeAction={() => setIsAddItemModalOpen(false)} />
      </Modal>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {isLoading && !showScanner && <Loader />}
      <div className={styles.textWrapperStyle}>
        <p className={styles.titleStyle}>Add your belonging or Return found items!</p>
        <div className={styles.descWrapperStyle}>
          <p className={styles.descStyle}>
            Scan QR or enter the SNO number present on the tag attached to the item to add your
            belongings or return found items.
          </p>
        </div>
      </div>
      <Input
        labelText="Unique ID or SNO*"
        placeholder="Enter SNO"
        value={sno}
        onChange={(e) => {
          setSno(e.target.value);
          setError(null);
        }}
        rightIcon={error ? infoCircleFillRedIcon : chevronRightGrayIcon}
        onClickRightIcon={() => {
          verifySno(sno);
        }}
        hasError={error}
      />
      <div className={styles.qrTitleWrapperStyle}>
        <span className={styles.descStyle}>or</span>
        <NavLink className={styles.qrLinkTitleStyle} onClick={() => setShowScanner(true)}>
          Scan QR code
        </NavLink>
      </div>
      {renderItemModal()}
      {renderAddItemModal()}
      {renderScanner()}
    </div>
  );
};

export default AddOrReturnItem;
