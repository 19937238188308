import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import Image from 'components/common/image';
import { closeCircleFilllGrayIcon, googleIcon, hideGrayIcon, showGrayIcon } from 'resources/images';
import { useStrings } from 'providers/stringsprovider';
import Input from 'components/common/input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from 'providers/authprovider';
import styles from './styles.module.css';
import Button from 'components/common/button';
import { useNavigate } from 'react-router';
import ProgressBar from 'components/common/progress-bar';
import { signupApi } from 'networking/api/auth';
import Loader from 'components/common/loader';
import { setAxiosSession } from 'utils/auth';
import { resendVerifictionLinkApi } from 'networking/api/user';

const Signup = () => {
  const { authError, user, setAuthError, setUser, setIsLoggedIn } = useAuth();
  const { strings } = useStrings();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [pwVisible, setPwVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [linkResendMsg, setLinkResendMsg] = useState('');
  const [schemaObj, setSchemaObj] = useState({
    full_name: Yup.string().required(strings.fullNameMandatory),
  });

  let pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, { cluster: 'eu' });

  useEffect(() => {
    let channels = [];
    if (user && user.verified_status === false) {
      let channel = pusher.subscribe(`channel-${user._id}`);
      channels.push(channel);
      channel.bind('verification', (data) => {
        if (data.verified_status) {
          setUser({
            ...user,
            verified_status: true,
          });
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...user,
              verified_status: true,
            })
          );
        }
      });
    }
    return () => {
      channels.forEach((channel) => channel.unsubscribe(`channel-${user._id}`));
    };
  }, [user]);

  useEffect(() => {
    setTimeout(() => {
      setLinkResendMsg('');
    }, 5000);
  }, [linkResendMsg]);

  useEffect(() => {
    if (step === 1) {
      setSchemaObj({
        full_name: Yup.string().required(strings.fullNameMandatory),
      });
    }
    if (step === 2) {
      setAuthError(null);
      formik.setTouched({});
      setSchemaObj({
        ...schemaObj,
        email: Yup.string().email(strings.enterValidMail).required(strings.emailRequired),
        password: Yup.string()
          .min(6, strings.passwordValidationText)
          .matches(/[A-Z]/, strings.passWordNeedUpperCaseText)
          .required(strings.passwordRequired),
      });
    }
  }, [step]);

  const signupAction = async (values, { resetForm }) => {
    if (step === 1) {
      setStep(2);
    }
    if (step === 2) {
      setEmail(values.email);
      try {
        setAuthError(null);
        setIsLoading(true);
        let response = await signupApi(values);
        const { type, user, accessToken, message } = response.data;
        if (type === 'success') {
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('user', JSON.stringify(user));
          setUser(user);
          setAxiosSession(accessToken);
          setStep(3);
          setIsLoading(false);
        } else {
          setAuthError(message);
          setUser(null);
          setIsLoading(false);
        }
      } catch (error) {
        setUser(null);
        setAuthError(error.message);
        setIsLoading(false);
      }
    }
    if (step === 3) {
      resendVerifyLinkAction();
    }
  };
  const resendVerifyLinkAction = async () => {
    try {
      setIsLoading(true);
      const response = await resendVerifictionLinkApi({ email: email });
      const { type, message } = response.data;
      if (type === 'success') {
        setLinkResendMsg(message);
        setIsLoading(false);
      } else {
        setAuthError(message);
        setIsLoading(false);
      }
    } catch (error) {
      setAuthError(error.message);
      setIsLoading(false);
    }
  };
  const signupSchema = Yup.object().shape(schemaObj);
  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      password: '',
      type: 1,
    },
    validationSchema: signupSchema,
    onSubmit: signupAction,
  });

  const renderHeaderSection = () => {
    return (
      <div className={styles.headerSectionStyle}>
        <p className={styles.backTextStyle}>
          {strings.step} {step} of 3
        </p>
        <Image
          src={closeCircleFilllGrayIcon}
          onClick={() => navigate(-1)}
          containerStyle={styles.closeIconStyle}
        />
      </div>
    );
  };

  const renderFormSection = () => {
    return (
      <div className={styles.formWrapperStyle}>
        <form onSubmit={formik.handleSubmit} className={styles.formSubWrapperStyle}>
          {/* top section */}
          <div className={styles.formTopSecctionStyle}>
            <p className={styles.titleTextStyle}>
              {' '}
              {step !== 3 ? strings.createAnAccountText : strings.verifyHeaderText}
            </p>
            <p className={styles.descTextStyle}>
              {step !== 3
                ? strings.haveAnAccount
                : `${strings.weHaveSentMail} "${email}" ${strings.verifyDesc}`}
              {step !== 3 && (
                <span onClick={() => navigate('/login')} className={styles.loginTextStyle}>
                  &nbsp;{strings.logIn}
                </span>
              )}
            </p>
            {step === 3 && linkResendMsg !== '' && (
              <p className={styles.authErrorTextStyle}>{linkResendMsg}</p>
            )}
          </div>
          {/* middle Section */}
          {step !== 3 && renderInputSection()}
          {renderButtons()}
        </form>
      </div>
    );
  };

  const renderInputSection = () => {
    return (
      <div className={styles.inputContainerStyle}>
        {step === 1 && (
          <Input
            name="full_name"
            labelText={strings.fullName}
            placeholder={strings.enterFullName}
            value={formik.values.full_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.full_name && formik.errors.full_name ? formik.errors.full_name : ''
            }
          />
        )}
        {step === 2 && (
          <React.Fragment>
            <Input
              name="email"
              labelText={strings.email}
              placeholder={strings.enterEmail}
              value={formik.values.email}
              onChange={(e) => {
                formik.handleChange(e);
                setAuthError(null);
              }}
              onBlur={formik.handleBlur}
              hasError={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            />
            <Input
              name="password"
              type={pwVisible ? 'text' : 'password'}
              labelText={strings.password}
              placeholder={strings.enterPassword}
              value={formik.values.password}
              onChange={(e) => {
                formik.handleChange(e);
                setAuthError(null);
              }}
              onBlur={formik.handleBlur}
              rightIcon={pwVisible ? showGrayIcon : hideGrayIcon}
              onClickRightIcon={() => setPwVisible(!pwVisible)}
              // rightIconStyle={styles.passwordRightIconStyle}
              hasError={
                formik.touched.password && formik.errors.password ? formik.errors.password : ''
              }
            />
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className={styles.buttonContainerStyle}>
        {step === 2 && authError && <p className={styles.authErrorTextStyle}>{authError}</p>}
        <Button
          type={'submit'}
          title={
            step === 1
              ? strings.next
              : step === 2
              ? strings.createAnAccountText
              : strings.resendLink
          }
          btnStyle={styles.btnStyle}
        />
        {step !== 3 && (
          <Button
            leftIcon={googleIcon}
            title={strings.continueWithGoogle}
            btnStyle={styles.btnStyle}
            variant={'light'}
            // onClick={() => setOpen(true)}
          />
        )}
      </div>
    );
  };
  return (
    <div className={styles.signupContainerStyle}>
      {isLoading && <Loader />}
      <ProgressBar size={20} percentage={step === 1 ? 33.33 : step === 2 ? 66.666 : 100} />
      <div className={styles.subContainerSyle}>
        {renderHeaderSection()}
        {renderFormSection()}
      </div>
    </div>
  );
};

export default Signup;
