import React, { useState } from 'react';
// import ModalPopUp from '../../components/ModalBox';
import { useNavigate, useLocation } from 'react-router-dom';
import Input from 'components/common/input';
import styles from './styles.module.css';
import { useStrings } from 'providers/stringsprovider';
import Button from 'components/common/button';
import {
  checkGrayIcon,
  chevronDownBlackIcon,
  chevronLeftBlackIcon,
  chevronUpBlackIcon,
  closeBlackIcon,
  closeCircleFilllGrayIcon,
  deleteRedIcon,
  minusGrayIcon,
  plusGrayIcon,
  productSampleIcon,
} from 'resources/images';
import Image from 'components/common/image';
import classNames from 'classnames';
import { useUserData } from 'providers/userdataprovider';
import { addToCartApi } from 'networking/api/cart';
import { buyItemsApi } from 'networking/api/order';
import routePaths from 'routing/paths';

const CheckOut = () => {
  const { strings } = useStrings();
  const navigate = useNavigate();
  const { cartData, setCartData, setOrders } = useUserData();
  const [open, setOpen] = useState(false);
  const [contactDetails, setContactDetails] = useState(true);
  const [address, setAddress] = useState(true);
  const [payment, setPayment] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [doreNumber, setDoreNumber] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [stateCountry, setStateCountry] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const getCartTotalPrice = () => {
    return cartData?.items?.reduce(
      (acc, value) => acc + value.quantity * value.product.price.selling_price,
      0
    );
  };

  const displayPrice = (val, currency = 'usd') => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(val);
  };

  async function updateQuantity(product_id, quantity) {
    try {
      setCartLoading(true);
      let res = await addToCartApi({ product_id, quantity });
      const { type, data, message } = res.data;
      if (type === 'success') {
        setCartData(data);
      }
      setCartLoading(false);
    } catch (error) {
      setCartLoading(false);
      console.log('Update quantity catch', error.message);
    }
  }

  async function handlePurchaseItems() {
    try {
      setPurchaseLoading(true);
      let res = await buyItemsApi({
        products: cartData.items.map((item) => ({
          product_id: item.product._id,
          quantity: item.quantity,
        })),
        currency: cartData?.items[0]?.product.price.currency,
      });
      const { type, data, message } = res.data;
      if (type === 'success') {
        setOrders((prev) => [data, ...prev]);
        setCartData(null);
        navigate(routePaths.myPurchases);
      } else {
        console.log(message || 'Something went wrong');
      }
      setPurchaseLoading(false);
    } catch (error) {
      setPurchaseLoading(false);
      console.log('Update quantity catch', error.message);
    }
  }

  const handleContactDetailsDone = () => {
    if (firstName === '' || lastName === '' || email === '' || phoneNumber === '') {
      return false;
    } else {
      return true;
    }
  };
  const handleAddressDetailsDone = () => {
    if (doreNumber === '' || city === '' || zipCode === '' || stateCountry === '') {
      return false;
    } else {
      return true;
    }
  };

  const handlePaymentDetailsDone = () => {
    if (cardHolderName === '' || cardNumber === '' || expiryDate === '' || cvv === '') {
      return false;
    } else {
      return true;
    }
  };

  const handleExpiryDate = (text) => {
    if (text.length == 2 && expiryDate.length == 1) {
      text += '/';
    } else if (text.length == 2 && expiryDate.length == 3) {
      text = text.substring(0, text.length - 1);
    }
    setExpiryDate(text);
  };

  const handleBtnDisable = () => {
    if (handleContactDetailsDone() && handleAddressDetailsDone() && handlePaymentDetailsDone()) {
      return false;
    } else {
      return true;
    }
  };

  const LeftInputConatainers = () => {
    return (
      <div className={styles.checkOutPageInsideContainerStyle}>
        <div className={styles.checkoutPageInputContainerStyle}>
          <div
            className={styles.checkoutHeaderContainerStyle}
            onClick={() => setContactDetails(!contactDetails)}
          >
            <div className={styles.checkOutHeaderInsideContainerStyle}>
              <Image
                src={checkGrayIcon}
                containerStyle={classNames(
                  styles.checkIconWrapperStyle,
                  handleContactDetailsDone() && styles.completedStepViewStyle
                )}
              />
              <p className={styles.contactDetailsTextStyle}>{strings.contactDetails}</p>
            </div>
            <Image
              src={contactDetails ? chevronUpBlackIcon : chevronDownBlackIcon}
              containerStyle={styles.downArrowStyle}
            />
          </div>
          {contactDetails && (
            <div className={styles.contactInputContainerStyle}>
              <div className={styles.contactDetailsInputContainerStyle}>
                <Input
                  labelText={strings.firstName}
                  placeholder={strings.enterFirstName}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Input
                  labelText={strings.lastName}
                  placeholder={strings.enterLastName}
                  type={'text'}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className={styles.contactDetailsInputContainerStyle}>
                <Input
                  labelText={strings.emailAddress}
                  placeholder={strings.enterEmailId}
                  type={'email'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className={styles.contactDetailsInputContainerStyle}>
                <Input
                  labelText={strings.phoneNumber}
                  placeholder={strings.enterYourPhnNumber}
                  type={'text'}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.checkoutPageInputContainerStyle}>
          <div className={styles.checkoutHeaderContainerStyle} onClick={() => setAddress(!address)}>
            <div className={styles.checkOutHeaderInsideContainerStyle}>
              <Image
                src={checkGrayIcon}
                containerStyle={classNames(
                  styles.checkIconWrapperStyle,
                  handleAddressDetailsDone() && styles.completedStepViewStyle
                )}
              />
              <p className={styles.contactDetailsTextStyle}>{strings.addressInfo}</p>
            </div>
            <Image src={address ? chevronUpBlackIcon : chevronDownBlackIcon} />
          </div>
          {address && (
            <div className={styles.contactInputContainerStyle}>
              <div className={styles.contactDetailsInputContainerStyle}>
                <Input
                  labelText={strings.doorNoAddress}
                  placeholder={strings.enterAddress}
                  type={'text'}
                  value={doreNumber}
                  onChange={(e) => setDoreNumber(e.target.value)}
                />
              </div>
              <div className={styles.contactDetailsInputContainerStyle}>
                <Input
                  labelText={strings.city}
                  placeholder={strings.enterCity}
                  type={'text'}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <Input
                  labelText={strings.zipCode}
                  placeholder={strings.enterZipCode}
                  type={'text'}
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </div>
              <div className={styles.contactDetailsInputContainerStyle}>
                <Input
                  labelText={strings.stateOrCountry}
                  placeholder={strings.enterStateOrCountry}
                  type={'text'}
                  value={stateCountry}
                  onChange={(e) => setStateCountry(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.checkoutPageInputContainerStyle}>
          <div className={styles.checkoutHeaderContainerStyle} onClick={() => setPayment(!payment)}>
            <div className={styles.checkOutHeaderInsideContainerStyle}>
              <Image
                src={checkGrayIcon}
                containerStyle={classNames(
                  styles.checkIconWrapperStyle,
                  handlePaymentDetailsDone() && styles.completedStepViewStyle
                )}
              />
              <p className={styles.contactDetailsTextStyle}>{strings.payment}</p>
            </div>
            <Image src={payment ? chevronUpBlackIcon : chevronDownBlackIcon} />
          </div>
          {payment && (
            <div className={styles.contactInputContainerStyle}>
              <div className={styles.contactDetailsInputContainerStyle}>
                <Input
                  labelText={strings.cardHolderName}
                  placeholder={strings.enterNameOnTheCard}
                  type="text"
                  value={cardHolderName}
                  onChange={(e) => setCardHolderName(e.target.value)}
                />
              </div>

              <div className={styles.contactDetailsInputContainerStyle}>
                <Input
                  labelText={strings.debitCard}
                  placeholder={strings.enterDebitCard}
                  type="number"
                  value={cardNumber}
                  min={1}
                  max={16}
                  onChange={(e) => setCardNumber(e.target.value)}
                />
              </div>
              <div className={styles.contactDetailsInputContainerStyle}>
                <Input
                  labelText={strings.expiry}
                  placeholder={strings.expiryPlaceHolder}
                  type={'text'}
                  value={expiryDate}
                  onChange={(e) => handleExpiryDate(e.target.value)}
                />
                <Input
                  labelText={strings.cvv}
                  placeholder={strings.cvvPlaceHolder}
                  type={'password'}
                  value={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const RightOrderContainery = () => {
    return (
      <div className={styles.orderSummeryStyle}>
        <div className={styles.orderedProductsSummeryContainerStyle}>
          <p className={styles.orderSummeryTextStyle}>{strings.orderSummery}</p>
          <div className={styles.noOfOrderedProductsContainerStyle}>
            {cartData?.items?.map((item, index) => (
              <div key={index} className={styles.orderProductContainerStyle}>
                <Image
                  src={item.product.images.thumbnail || productSampleIcon}
                  alt="icon"
                  containerStyle={styles.itemImgContainerStyle}
                  imgStyle={styles.itemImgStyle}
                />
                <div className={styles.orderedProductRightContainerStyle}>
                  <div className={styles.orderProductPricesContainerStyle}>
                    <p className={styles.orderProductTagsTextStyle}>{item.product?.name}</p>
                    <p className={styles.orderProductPriceTextStyle}>
                      {strings.price}
                      {`(x${item.quantity})`} :{' '}
                      {displayPrice(
                        item.product?.price.selling_price,
                        item?.product?.price.currency
                      )}
                    </p>
                  </div>
                  <div className={styles.orderProductDetailsContainerStyle}>
                    <div className={styles.addMinusContainerSTyle}>
                      <Image
                        src={minusGrayIcon}
                        onClick={() =>
                          item.quantity > 1 && !cartLoading
                            ? updateQuantity(item.product._id, -1)
                            : null
                        }
                        containerStyle={styles.addIconWrapperStyle}
                      />
                      <p className={styles.countTextStyle}>
                        {String(item.quantity).padStart(2, '0')}
                      </p>
                      <Image
                        src={plusGrayIcon}
                        onClick={() => (!cartLoading ? updateQuantity(item.product._id, 1) : null)}
                        containerStyle={styles.addIconWrapperStyle}
                      />
                    </div>
                    {/* <Image src={deleteRedIcon} containerStyle={styles.iconWrapperStyle} /> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.dividerStyle}></div>
        <div className={styles.orderedProductsSummeryContainerStyle}>
          <Input
            placeholder={strings.discount}
            type={'text'}
            onChange={(e) => console.log(e.target.value)}
          />
          <div className={styles.costAndPayContainerStyle}>
            <div className={styles.costListContainerStyle}>
              <div className={styles.productPriceContainerStyle}>
                <p className={styles.priceSubHeadTextStyle}>{strings.subTotal}</p>
                <p className={styles.costTextStyle}>
                  {' '}
                  {displayPrice(getCartTotalPrice(), cartData?.items[0]?.product.price.currency)}
                </p>
              </div>
              <div className={styles.productPriceContainerStyle}>
                <p className={styles.priceSubHeadTextStyle}>
                  {strings.shipping}&nbsp;
                  <span className={styles.fillAddressTextStyle}>{strings.fillAddress}</span>
                </p>
              </div>
              <div className={styles.productPriceContainerStyle}>
                <p className={styles.priceSubHeadTextStyle}>{strings.tax}</p>
                <p className={styles.costTextStyle}>
                  {' '}
                  +{displayPrice(0, cartData?.items[0]?.product.price.currency)}
                </p>
              </div>
              <div className={styles.productPriceContainerStyle}>
                <p className={styles.priceSubHeadTextStyle}>{strings.discount}</p>
                <p className={styles.costTextStyle}>
                  - {displayPrice(0, cartData?.items[0]?.product.price.currency)}
                </p>
              </div>
            </div>
            <div className={styles.dividerStyle}></div>
            <div className={styles.totalPriceContainerStyle}>
              <p className={styles.totalTextStyle}>
                {strings.total}
                <span className={styles.withoutShippingtextStyle}>{strings.withoutShipping}</span>
              </p>
              <p className={styles.totalPriceTextStyle}>
                {displayPrice(getCartTotalPrice(), cartData?.items[0]?.product.price.currency)}
              </p>
            </div>
          </div>
          <Button
            title={`Pay - ${displayPrice(
              getCartTotalPrice(),
              cartData?.items[0]?.product.price.currency
            )}`}
            btnStyle={styles.payBtnStyle}
            onClick={() => handlePurchaseItems()}
            disabled={handleBtnDisable()}
            isLoading={purchaseLoading}
          />
        </div>
      </div>
    );
  };
  return (
    <div className={styles.checkoutPageMainContainerStyle}>
      <div className={styles.closeContainerStyle}>
        <p className={styles.checkoutTextStyle}>{strings.checkout}</p>
        <Image
          src={closeCircleFilllGrayIcon}
          onClick={() => navigate(-1)}
          containerStyle={styles.closeIconStyle}
        />
      </div>
      <div className={styles.checkOutGridContainerStyle}>
        {LeftInputConatainers()}
        {RightOrderContainery()}
      </div>
      {/* <ModalPopUp open={open} handleClose={handleClose}>
        <div className={styles.modalContainerStyle}>
          <div className={styles.orderPlacedImgWrapperStyle}>
            <img src={orderPlacedImg} className={styles.imgStyle} />
          </div>
          <div className={styles.modalInsideContainerStyle}>
            <div className={styles.textContainerStyle}>
              <p className={styles.modalHeadertextStyle}>{strings.successfulPurchase}</p>
              <p className={styles.modalDescTextStyle}>{strings.successfulPurchaseDesc}</p>
            </div>
            <div className={styles.btnContainerStyle}>
              <Button
                title={strings.trackOrder}
                btnStyles={styles.trackOrderBtnStyle}
                onClick={() => console.log('888')}
              />
              <Button
                title={strings.trackOrderLater}
                btnStyles={styles.doitLaterBtnStyle}
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </div>
      </ModalPopUp> */}
    </div>
  );
};

export default CheckOut;
