import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/common/input';
import Image from 'components/common/image';
import Loader from 'components/common/loader';
import styles from './styles.module.css';
import { chevronRightBlackIcon, closeGrayIcon } from 'resources/images';

const AddorReturnCard = (props) => {
  const {
    title,
    type,
    description,
    isLoading,
    error,
    onConfirmAction = () => {},
    onClose = () => {},
    onChange = () => {},
    tapOnScanBtn = () => {},
  } = props;

  return (
    <div className={styles.returnWrapperStyle}>
      {isLoading && <Loader />}
      <div className={styles.modalHeaderWrapperStyle}>
        <div className={styles.headerWrapperStyle}>
          <p className={styles.headerTextStyle}>{title}</p>
          <Image src={closeGrayIcon} onClick={onClose} />
        </div>
        <p className={styles.modalDescTextStyle}>
          {description} <span>{type === 'add' && 'click here'}</span>
        </p>
      </div>
      <div className={styles.inputWrapperStyle}>
        <Input
          inputLabelStyle={styles.labelTextStyle}
          inputStyle={styles.inputStyle}
          labelText={'Unique ID or SNO*'}
          placeholder={'Enter 8-digit SNO'}
          rightIcon={chevronRightBlackIcon}
          onChange={(e) => onChange(e)}
          onClickRightIcon={onConfirmAction}
          hasError={error}
        />
      </div>
      <p className={styles.scanQrTextStyle}>
        or{' '}
        <span onClick={tapOnScanBtn} className={styles.scanQrLinkStyle}>
          Scan QR code
        </span>
      </p>
    </div>
  );
};

AddorReturnCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf(['add', 'return']),
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onConfirmAction: PropTypes.func,
  tapOnScanBtn: PropTypes.func,
  error: PropTypes.string,
};

export default AddorReturnCard;
