import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useAuth } from 'providers/authprovider';
import Avatar from 'components/common/avatar';
import Image from 'components/common/image';
import Button from 'components/common/button';
import {
  bellGrayIcon,
  chevronRightGrayIcon,
  clockGrayIcon,
  listGrayIcon,
  logoutRedIcon,
  messageGrayIcon,
  pencilEditGrayIcon,
  scanWhiteIcon,
} from 'resources/images';
import routePaths from 'routing/paths';
import { useLocation, useNavigate } from 'react-router';
import { useUserData } from 'providers/userdataprovider';
import styles from './styles.module.css';

const UserMenu = (props) => {
  const { containerStyle, setState = () => {} } = props;
  const { user, logout } = useAuth();
  const { chatList } = useUserData();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const renderProfileSection = () => {
    return (
      <div className={styles.profileWrapperStyle}>
        <div className={styles.profileSubWrapperStyle}>
          <Avatar
            src={user?.image}
            label={user?.full_name}
            alt="icon"
            containerStyle={styles.profileIconStyle}
          />
          <div className={styles.userDetailsSectionStyle}>
            <p className={styles.userTitleTextStyle}>{user?.full_name}</p>
            <p className={styles.userDescTextStyle}>{user?.email}</p>
          </div>
        </div>
        <Image
          src={logoutRedIcon}
          containerStyle={styles.menuOptionIconStyle}
          onClick={() => logout()}
        />
      </div>
    );
  };

  const renderScanBtn = () => {
    return (
      <div className={styles.btnContainerStyle}>
        <Button
          title={'Add or Return items'}
          leftIcon={scanWhiteIcon}
          btnStyle={styles.btnStyle}
          onClick={() => navigate(routePaths.addOrReturnItem)}
        />
      </div>
    );
  };

  const renderMenuOptions = () => {
    const chatCount = chatList.reduce((acc, val) => acc + val.count, 0);
    return (
      <div className={styles.menuOptionsWrapperStyle}>
        <div
          className={classNames(
            styles.eachOptionViewStyle,
            pathname === routePaths.profile && styles.activeOptionStyle
          )}
          onClick={() => {
            navigate(routePaths.profile);
            setState(false);
          }}
        >
          <div className={styles.optionleftStyle}>
            <Image src={pencilEditGrayIcon} containerStyle={styles.menuOptionIconStyle} />
            <p className={styles.menuOptionTitleStyle}>Edit profile</p>
          </div>
          <Image src={chevronRightGrayIcon} containerStyle={styles.menuOptionRightIconStyle} />
        </div>
        <div
          className={classNames(
            styles.eachOptionViewStyle,
            pathname === routePaths.notifications && styles.activeOptionStyle
          )}
          onClick={() => {
            navigate(routePaths.notifications);
            setState(false);
          }}
        >
          <div className={styles.optionleftStyle}>
            <Image src={bellGrayIcon} containerStyle={styles.menuOptionIconStyle} />
            <p className={styles.menuOptionTitleStyle}>Notifications</p>
          </div>
          <Image src={chevronRightGrayIcon} containerStyle={styles.menuOptionRightIconStyle} />
        </div>
        <div
          className={classNames(
            styles.eachOptionViewStyle,
            pathname === routePaths.myItems && styles.activeOptionStyle
          )}
          onClick={() => {
            navigate(routePaths.myItems);
            setState(false);
          }}
        >
          <div className={styles.optionleftStyle}>
            <Image src={listGrayIcon} containerStyle={styles.menuOptionIconStyle} />
            <p className={styles.menuOptionTitleStyle}>My Items</p>
          </div>
          <Image src={chevronRightGrayIcon} containerStyle={styles.menuOptionRightIconStyle} />
        </div>
        <div
          className={classNames(styles.eachOptionViewStyle)}
          onClick={() => navigate(routePaths.chat)}
        >
          <div className={styles.optionleftStyle}>
            <Image src={messageGrayIcon} containerStyle={styles.menuOptionIconStyle} />
            <p className={styles.menuOptionTitleStyle}>
              Messages {chatCount > 0 && `(${chatCount})`}
            </p>
          </div>
          <Image src={chevronRightGrayIcon} containerStyle={styles.menuOptionRightIconStyle} />
        </div>
        <div
          className={classNames(
            styles.eachOptionViewStyle,
            pathname === routePaths.myPurchases && styles.activeOptionStyle
          )}
          onClick={() => {
            navigate(routePaths.myPurchases);
            setState(false);
          }}
        >
          <div className={styles.optionleftStyle}>
            <Image src={clockGrayIcon} containerStyle={styles.menuOptionIconStyle} />
            <p className={styles.menuOptionTitleStyle}>My Purchases</p>
          </div>
          <Image src={chevronRightGrayIcon} containerStyle={styles.menuOptionRightIconStyle} />
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(styles.containerStyle, containerStyle)}>
      {renderProfileSection()}
      {renderScanBtn()}
      {renderMenuOptions()}
    </div>
  );
};

UserMenu.propTypes = {
  containerStyle: PropTypes.string,
  setState: PropTypes.func,
};

export default UserMenu;
