import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Image from '../image';
import styles from './styles.module.css';
import Loader from '../loader';

const Button = (props) => {
  const {
    variant = 'primary',
    size = 'lg',
    type = 'button',
    title,
    onClick = () => {},
    disabled = false,
    isLoading,
    reference,
    loaderStyle,
    btnStyle,
    leftIcon,
    leftIconStyle,
    rightIcon,
    rightIconStyle,
    children,
  } = props;

  let colorStyle = styles.primaryBtnStyle;
  if (variant === 'dark') {
    colorStyle = styles.darkBtnStyle;
  } else if (variant === 'light') {
    colorStyle = styles.whiteBtnStyle;
  } else if (variant === 'gray') {
    colorStyle = styles.grayBtnStyle;
  } else {
    colorStyle = styles.primaryBtnStyle;
  }

  let sizeStyle = styles.largeBtnStyle;
  if (size === 'xs') {
    sizeStyle = styles.extraSmallBtnStyle;
  } else if (size === 'sm') {
    sizeStyle = styles.smallBtnStyle;
  } else if (size === 'md') {
    sizeStyle = styles.mediumBtnStyle;
  } else if (size === 'lg') {
    sizeStyle = styles.largeBtnStyle;
  } else {
    sizeStyle = styles.largeBtnStyle;
  }

  return (
    <button
      type={type}
      className={classNames(
        styles.btnStyle,
        colorStyle,
        sizeStyle,
        disabled && styles.disabledStyle,
        btnStyle
      )}
      onClick={onClick}
      disabled={disabled}
      ref={reference}
    >
      {leftIcon && <Image containerStyle={leftIconStyle} src={leftIcon} alt="icon" />}
      {title}
      {rightIcon && <Image containerStyle={rightIconStyle} src={rightIcon} alt="icon" />}
      {children && children}
      {isLoading && <Loader loaderStyle={classNames(styles.loaderStyle, loaderStyle)} />}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'light', 'dark', 'gray']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  type: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  btnStyle: PropTypes.string,
  leftIcon: PropTypes.string,
  leftIconStyle: PropTypes.string,
  rightIcon: PropTypes.string,
  rightIconStyle: PropTypes.string,
  loaderStyle: PropTypes.string,
};

export default Button;
