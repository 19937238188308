import React, { useEffect, useState } from 'react';
import Image from 'components/common/image';
import {
  chevronLeftWhiteIcon,
  chevronRightBlackIcon,
  chevronRightBlueIcon,
  deleteRedIcon,
  documentGrayIcon,
  downloadBlueIcon,
  frameWhiteIcon,
  itemsGrayIcon,
  pencilEditGrayIcon,
  plusSqareFillBlueIcon,
  plusWhiteIcon,
  ticketGrayIcon,
  verticalMenuWhiteIcon,
} from 'resources/images';
import Button from 'components/common/button';
import Modal from 'components/common/modal';
import AddorReturnCard from 'components/add-or-return-card';
import {
  checkQrApi,
  deleteItemApi,
  updateItemConversationStateApi,
  updateItemLostStateApi,
} from 'networking/api/myItems';
import { useAuth } from 'providers/authprovider';
import Scanner from 'components/scanner';
import AddorEditItem from 'sections/add-or-edit-item';
import { useUserData } from 'providers/userdataprovider';
import classNames from 'classnames';
import { getItemIcon } from 'functions';
import useWindowDimensions from 'hooks/useWindowDimensions';
import PopOver from 'components/common/popover';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Switch from 'components/common/switch';
import Loader from 'components/common/loader';
import ImagePreview from 'components/common/image-preview';
import styles from './styles.module.css';

const MyItems = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedSno = searchParams.get('selected');
  const { user } = useAuth();
  const { myItems, setMyItems } = useUserData();
  const { width } = useWindowDimensions();
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [sno, setSno] = useState('');
  const [error, setError] = useState('');
  const [showScanner, setShowScanner] = useState(false);
  const [scanResultModal, setScanResultModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showImgPreview, setShowImgPreview] = useState(false);
  const [itemOptionsModal, setItemOptionsModal] = useState(false);
  const [itemOptionsRef, setItemOptionsRef] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [deleteItemError, setDeleteItemError] = useState('');
  const [itemActionLoading, setItemActionLoading] = useState(false);
  //   console.log('myItems', myItems);

  const Options = [
    {
      title: 'Edit item',
      icon: pencilEditGrayIcon,
    },
    {
      title: 'Subscription plans',
      icon: ticketGrayIcon,
    },
    {
      title: 'Delete item',
      icon: deleteRedIcon,
    },
  ];

  useEffect(() => {
    if (selectedSno) {
      setSelectedItem(myItems.find((item) => item.sno === selectedSno));
    } else {
      setSelectedItem(null);
    }
  }, [selectedSno, myItems]);

  useEffect(() => {
    if (deleteItemError) {
      setTimeout(() => {
        setDeleteItemError('');
      }, 5000);
    }
  }, [deleteItemError]);

  const handleScan = (url, error) => {
    if (url) {
      alert(url);
      let data = url?.split('/');
      let id = data[data.length - 1];
      if (id) {
        verifySno(id);
      }
    }
    if (error) {
      console.log('error', error.message);
    }
  };

  const onSelectOption = (option) => {
    setSelectedOption(option);
    if (option === 'Edit item') {
      setIsAddItemModalOpen(true);
    } else if (option === 'Delete item') {
      setDeleteItemModal(true);
    } else {
      setItemOptionsModal(false);
    }
  };

  const handleModalCloseClick = () => {
    setIsVerifyModalOpen(!isVerifyModalOpen);
  };

  const verifySno = async (id) => {
    try {
      if (id) {
        setIsLoading(true);
        let res = await checkQrApi({ qr_number: id });
        if (res.data.tag === 'not_available') {
          if (showScanner) {
            setScanResultModal(true);
          } else {
            setError('Invalid SNO.');
          }
          setIsLoading(false);
        }
        if (res.data.tag === 'used') {
          setError('SNO already used, please try another sno');
          setIsLoading(false);
        }
        if (res.data.tag === 'available') {
          setIsAddItemModalOpen(true);
          setSno(id);
          setIsLoading(false);
        }
      } else {
        setError('Please enter sno');
      }
    } catch (error) {}
  };

  async function handleDeleteItem() {
    try {
      setItemActionLoading(true);
      let res = await deleteItemApi(selectedItem._id);
      if (res.data.type === 'success') {
        setMyItems((prev) => {
          let previous_data = [...prev];
          previous_data = previous_data.filter((item) => item._id !== selectedItem._id);
          return previous_data;
        });
        searchParams.delete('selected');
        setSearchParams(searchParams);
        setDeleteItemModal(false);
      } else {
        setDeleteItemError(res.data.message);
      }
      setItemActionLoading(false);
    } catch (error) {
      setItemActionLoading(false);
      setDeleteItemError(error.message);
      console.log('Delete Item api catch', error.message);
    }
  }

  async function handleChangeItemLostState() {
    try {
      setItemActionLoading(true);
      let res = await updateItemLostStateApi({
        product_id: selectedItem._id,
        is_product_lost: !selectedItem.is_product_lost,
      });
      if (res.data.type === 'success') {
        setMyItems((prev) => {
          let previous_data = [...prev];
          let itemIndex = previous_data.findIndex((item) => item._id === selectedItem._id);
          previous_data[itemIndex].is_product_lost = !selectedItem.is_product_lost;
          return previous_data;
        });
      } else {
        console.log('Losts state api fail', res.data.message);
      }
      setItemActionLoading(false);
    } catch (error) {
      setItemActionLoading(false);
      console.log('Lost state api catch', error.message);
    }
  }

  async function handleChangeConversationState() {
    try {
      setItemActionLoading(true);
      let res = await updateItemConversationStateApi({
        product_id: selectedItem._id,
        conversations_state: !selectedItem.conversations_state,
      });
      if (res.data.type === 'success') {
        setMyItems((prev) => {
          let previous_data = [...prev];
          let itemIndex = previous_data.findIndex((item) => item._id === selectedItem._id);
          previous_data[itemIndex].conversations_state = !selectedItem.conversations_state;
          return previous_data;
        });
      } else {
        console.log('Conversations state api fail', res.data.message);
      }
      setItemActionLoading(false);
    } catch (error) {
      setItemActionLoading(false);
      console.log('Conversation state api catch', error.message);
    }
  }

  //   ui secction

  const renderEmptyItemContainer = () => {
    return (
      <div className={styles.emptySectionStyle}>
        <p className={styles.emptySectionHeaderStyle}>My items</p>
        <div className={styles.emptySectionSubWrapperStyle}>
          <Image src={documentGrayIcon} containerStyle={styles.documentIconStyle} />
          <p className={styles.emptySectionDescTextStyle}>
            You’ve no added items. Click below to start registering your belongings.
          </p>
          <Button
            title="Add items"
            onClick={() => setIsVerifyModalOpen(true)}
            leftIcon={plusWhiteIcon}
          />
        </div>
      </div>
    );
  };

  const renderVerifySnoModal = () => {
    return (
      <Modal
        show={isVerifyModalOpen}
        containerStyle={styles.verifySnoModalStyle}
        overlayStyle={styles.overlayStyle}
        onClose={handleModalCloseClick}
        showOverlay={true}
      >
        <AddorReturnCard
          title="Add your belonging"
          description="To began the process of registering an item, enter SNO or Scan QR printed on the physical tag. To purchase physical tags,"
          type="add"
          error={error}
          isLoading={isLoading}
          onClose={() => {
            handleModalCloseClick();
            setSno('');
            setError('');
          }}
          onChange={(e) => {
            setSno(e.target.value);
            setError('');
          }}
          onConfirmAction={() => {
            setError('');
            verifySno(sno);
          }}
          tapOnScanBtn={() => setShowScanner(true)}
        />
      </Modal>
    );
  };

  const renderScanner = () => {
    return (
      <Modal show={showScanner}>
        <Scanner
          handleScan={handleScan}
          alertModal={scanResultModal}
          onCloseScanner={() => {
            setShowScanner(false);
            setScanResultModal(false);
          }}
          onCloseRescanModal={() => setScanResultModal(false)}
        />
      </Modal>
    );
  };

  const renderAddItemModal = () => {
    return (
      <Modal
        show={isAddItemModalOpen}
        showOverlay
        overlayStyle={styles.overlayStyle}
        containerStyle={styles.addItemModalStyle}
        // onClose={() => setIsAddItemModalOpen(false)}
        // closeOnOutSideClick
      >
        <AddorEditItem
          item={selectedOption === 'Edit item' ? selectedItem : { sno: sno }}
          closeAction={() => {
            setIsAddItemModalOpen(false);
            setSelectedOption('');
          }}
        />
      </Modal>
    );
  };

  const renderDeleteItemModal = () => {
    return (
      <Modal
        show={deleteItemModal}
        containerStyle={styles.deleteModalStyle}
        overlayStyle={styles.deleteModalOverlayStyle}
        onClose={() => setDeleteItemModal(false)}
        closeOnOutSideClick
        showOverlay
      >
        <div className={styles.deleteModalContentStyle}>
          <p className={styles.deleteModalTitleStyle}>Delete item?</p>
          <p className={styles.deleteModalDescStyle}>
            Are you sure you want to delete this registered item from your items?
          </p>
        </div>
        <div className={styles.deleteModalBtnWrapperStyle}>
          <Button
            title="Cancel"
            btnStyle={styles.cancelBtnStyle}
            variant="gray"
            onClick={() => setDeleteItemModal(false)}
          />
          <Button title="Yes" btnStyle={styles.proceedBtnStyle} onClick={handleDeleteItem} />
        </div>
        {deleteItemError && <p className={styles.errorTextStyle}>{deleteItemError}</p>}
      </Modal>
    );
  };

  const renderLeftSection = () => {
    return (
      <div className={styles.leftSectionStyle}>
        <div className={styles.leftSectionHeaderBlockStyle}>
          <p className={styles.leftSectionHeaderTitleStyle}>My items</p>
          <Image src={plusSqareFillBlueIcon} onClick={() => setIsVerifyModalOpen(true)} />
        </div>
        <div className={styles.leftSectionBodyBlockStyle}>
          {myItems?.length > 0 &&
            myItems.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  styles.eachItemStyle,
                  item._id === selectedItem?._id ? styles.selectedItemStyle : ''
                )}
                onClick={() => setSearchParams({ selected: item.sno })}
              >
                <Image
                  src={getItemIcon(item?.category?.title)}
                  containerStyle={styles.itemCategoryIconStyle}
                />
                <div className={styles.itemDataBlockStyle}>
                  <p className={styles.itemTitleStyle}>{item.product_name}</p>
                  <p className={styles.itemDescStyle}>
                    SNO code: <span>{item.sno}</span>
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderRightSection = () => {
    return (
      <div className={styles.rightSectionStyle}>
        {!selectedItem ? (
          renderEmptySelectedItemContainer()
        ) : (
          <React.Fragment>
            {itemActionLoading && <Loader />}
            <div className={styles.itemImgSectionStyle}>
              <Image
                src={selectedItem?.product_image}
                containerStyle={styles.itemImgStyle}
                imgStyle={styles.imgStyle}
              />
              <div className={styles.rightHeaderBtnsWrapperStyle}>
                {selectedItem?.product_image && (
                  <React.Fragment>
                    <Image
                      src={width <= 867 ? chevronLeftWhiteIcon : frameWhiteIcon}
                      containerStyle={styles.rightHeaderbtnStyle}
                      imgStyle={styles.iconStyle}
                      onClick={() => (width <= 867 ? navigate(-1) : setShowImgPreview(true))}
                    />
                    {showImgPreview && (
                      <ImagePreview
                        src={selectedItem.product_image}
                        onClose={() => setShowImgPreview(false)}
                        onDone={() => setShowImgPreview(false)}
                      />
                    )}
                  </React.Fragment>
                )}
                <Image
                  src={verticalMenuWhiteIcon}
                  containerStyle={styles.rightHeaderbtnStyle}
                  imgStyle={styles.iconStyle}
                  onClick={() => setItemOptionsModal(true)}
                  reference={setItemOptionsRef}
                />
                {renderItemOptionsModal()}
                {renderDeleteItemModal()}
              </div>
            </div>
            {/* item ddetails section  */}
            <div className={styles.itemWrapperStyle}>
              <p className={styles.labelTextStyle}>Overview</p>
              <div className={styles.itemDetailsWrapperStyle}>
                <Image
                  src={getItemIcon(selectedItem?.category?.title)}
                  containerStyle={styles.itemCategoryIconStyle}
                />
                <div className={styles.itemDataStyle}>
                  <p className={styles.titleTextStyle}>{selectedItem?.product_name}</p>
                  <p className={styles.descTextStyle}>
                    Unique code or SNO: <span>{selectedItem?.sno}</span>
                  </p>
                  <p className={styles.descTextStyle}>
                    Category: <span>{selectedItem?.category.title}</span>
                  </p>
                </div>
              </div>
              {/* item note section  */}
              <div className={styles.itemNoteViewStyle}>
                <p className={styles.descTextStyle}>
                  Note to finder: <span>{selectedItem?.note}</span>
                </p>
              </div>
            </div>
            {/* other options section */}
            <div className={styles.itemWrapperStyle}>
              {/* <div className={styles.itemOtherWrapperStyle}>
                <div className={styles.itemOtherSubWrapperStyle}>
                  <p className={styles.titleTextStyle}>Mark as lost</p>
                  <Switch
                    checked={selectedItem.is_product_lost}
                    onChange={handleChangeItemLostState}
                  />
                </div>
                <p className={styles.messageTextStyle}>
                  When enabled, the status of your registered item is set to “lost” and we will
                  notify your friends via email.
                </p>
              </div> */}
              <div className={styles.itemOtherWrapperStyle}>
                <div className={styles.itemOtherSubWrapperStyle}>
                  <p className={styles.titleTextStyle}>Conversations</p>
                  <Switch
                    checked={selectedItem.conversations_state}
                    onChange={handleChangeConversationState}
                  />
                </div>
                <p className={styles.messageTextStyle}>
                  When disabled, finders of your item cannot send you text messages.
                </p>
              </div>
            </div>
            {/* archive section  */}
            {/* <div className={styles.archiveSectionStyle}>
              <div className={styles.archiveSubSectionStyle}>
                <Image src={downloadBlueIcon} />
                <p className={styles.arciveTextStyle}>Chat archives</p>
              </div>
              <Image src={chevronRightBlueIcon} />
            </div> */}
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderItemOptionsModal = () => {
    return (
      <PopOver
        show={itemOptionsModal}
        onClose={() => setItemOptionsModal(false)}
        reference={itemOptionsRef}
        placement="bottom-end"
        containerStyle={styles.optionsModalStyle}
      >
        {Options.map((opt, index) => (
          <div
            className={styles.eachOptionStyle}
            key={index}
            onClick={() => onSelectOption(opt.title)}
          >
            <Image src={opt.icon} />
            <p className={classNames(styles.optionTitleStyle)}>{opt.title}</p>
          </div>
        ))}
      </PopOver>
    );
  };

  const renderEmptySelectedItemContainer = () => {
    return (
      <div className={styles.emptyItemContainerStyle}>
        <Image src={itemsGrayIcon} />
        <p className={styles.emptyContainerTextStyle}>
          Click on the individual item to see more details/ edit item/delete item /and more.
        </p>
      </div>
    );
  };

  return (
    <React.Fragment>
      {myItems.length > 0 ? (
        <React.Fragment>
          {renderLeftSection()}
          {(selectedItem || width > 867) && renderRightSection()}
        </React.Fragment>
      ) : (
        renderEmptyItemContainer()
      )}
      {renderVerifySnoModal()}
      {renderScanner()}
      {renderAddItemModal()}
    </React.Fragment>
  );
};

export default MyItems;
