import React from 'react';
import Image from 'components/common/image';
import { awsIcon, ebayIcon, googleGrayIcon, iosIcon, twitchIcon } from 'resources/images';
import styles from './styles.module.css';

const SocialSection = () => {
  let Data = [
    {
      icon: ebayIcon,
      title: '',
    },
    {
      icon: awsIcon,
      title: '',
    },
    {
      icon: googleGrayIcon,
      title: '',
    },
    {
      icon: twitchIcon,
      title: '',
    },
    {
      icon: iosIcon,
      title: '',
    },
  ];
  return (
    <div className={styles.mainPartnerContainerStyle}>
      <div className={styles.partnerContainerStyle}>
        <p className={styles.partnerDescTextStyle}>
          We collaborated with the leading brands to ensure the best services for our end users.
        </p>
        <div className={styles.partnerImgContainerStyle}>
          {Data.map((item, index) => (
            <Image
              src={item.icon}
              containerStyle={styles.imgWrapperStyle}
              imgStyle={styles.imgStyle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SocialSection;
