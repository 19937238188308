import React, { useContext, createContext, useEffect, useState } from 'react';
import EnglishStrings from 'resources/strings/strings-en.json';
import SwedishStrings from 'resources/strings/strings-swe.json';

export const StringsContext = createContext({
  strings: EnglishStrings,
  currentLanguage: '',
  setLanguage: (_value) => {
    return null;
  },
});

const StringsProvider = (props) => {
  const defaultLang = 'EN';
  const [language, setLanguage] = useState(defaultLang);

  const initialize = async () => {
    setLanguage(localStorage.getItem('language') || defaultLang);
  };

  useEffect(() => {
    initialize();
  }, []);

  const strings = () => {
    console.log('language', language);
    switch (language) {
      case 'EN':
        return EnglishStrings;
      case 'SE':
        return SwedishStrings;
      default:
        return EnglishStrings;
    }
  };

  return (
    <StringsContext.Provider
      value={{
        strings: strings(),
        currentLanguage: language,
        setLanguage: (value) => {
          localStorage.setItem('language', value);
          setLanguage(value);
        },
      }}
    >
      {props.children}
    </StringsContext.Provider>
  );
};

export default StringsProvider;

export const useStrings = () => {
  const context = useContext(StringsContext);

  if (context === undefined) {
    throw new Error('useStrings must be used within StringsProvider');
  }
  return context;
};
