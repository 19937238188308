import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useAppData } from 'providers/appdataprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Image from 'components/common/image';
import {
  addUserBlueIcon,
  cartBlackIcon,
  cartWhiteIcon,
  checkBlueIcon,
  checkGrayIcon,
  chevronLeftBlackIcon,
  chevronRightBlackIcon,
  closeBlackIcon,
  closeGrayIcon,
  foundiBlackIcon,
  foundiWhiteIcon,
  globeWhiteIcon,
  hamburgerBlackIcon,
  hamburgerWhiteIcon,
  userWhiteIcon,
} from 'resources/images';
import routePaths from 'routing/paths';
import Modal from 'components/common/modal';
import { useAuth } from 'providers/authprovider';
import UserMenu from 'components/user-menu';
import PopOver from 'components/common/popover';
import Button from 'components/common/button';
import Cart from 'components/cart';
import styles from './styles.module.css';

const NavBar = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { currentLanguage, setLanguage } = useAppData();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNavbarLight, setIsNavbarLight] = useState(false);
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const [isUserAuthModalOpen, setIsUserAuthModalOpen] = useState(false);
  const [userAuthRef, setUserAuthRef] = useState(null);
  const [isLangModalOpen, setIsLangModalOpen] = useState(false);
  const [langRef, setLangRef] = useState(null);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [cartRef, setCartRef] = useState(null);

  const languages = [
    { title: 'English', val: 'Eng' },
    { title: 'Swedish', val: 'Swe' },
  ];

  useEffect(() => {
    if (width > 867) {
      setIsMenuOpen(false);
      setIsNavbarLight(false);
      setIsUserProfileOpen(false);
    } else {
      setIsUserAuthModalOpen(false);
      setIsLangModalOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (
      isMenuOpen ||
      isUserProfileOpen ||
      isUserAuthModalOpen ||
      isLangModalOpen ||
      isCartModalOpen
    ) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'scroll';
    }
  }, [isMenuOpen, isUserProfileOpen, isUserAuthModalOpen, isLangModalOpen, isCartModalOpen]);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsNavbarLight(!isNavbarLight);
  };

  const renderMenuModal = () => {
    return (
      <Modal
        show={isMenuOpen}
        containerStyle={styles.menuModalStyle}
        onClose={handleMenuClick}
        closeOnOutSideClick
        showOverlay
      >
        {user ? (
          <div className={styles.menuItemBlockStyle}>
            <NavLink className={styles.menuLinkStyle} onClick={() => console.log('profile')}>
              My profile
            </NavLink>
            <Image src={chevronRightBlackIcon} onClick={() => setIsUserProfileOpen(true)} />
          </div>
        ) : (
          <div className={styles.menuItemTitleWrapperStyle}>
            <NavLink to={routePaths.login} className={styles.menuLinkBlueStyle}>
              Login
            </NavLink>
            <span>(or)</span>
            <NavLink to={routePaths.signup} className={styles.menuLinkBlueStyle}>
              Sign up
            </NavLink>
          </div>
        )}
        <NavLink to={routePaths.events} className={classNames(styles.menuLinkStyle)}>
          Events
        </NavLink>
        <NavLink to={routePaths.about} className={styles.menuLinkStyle}>
          About
        </NavLink>
        <NavLink to={routePaths.products} className={styles.menuLinkStyle}>
          Products
        </NavLink>
        {user && renderProfileOptions()}
      </Modal>
    );
  };

  const renderProfileOptions = () => {
    return (
      <Modal show={isUserProfileOpen} containerStyle={styles.profileMenuModalStyle}>
        <div className={styles.profileMenuHeaderStyle}>
          <Image src={chevronLeftBlackIcon} onClick={() => setIsUserProfileOpen(false)} />
          <Image
            src={closeGrayIcon}
            onClick={() => {
              setIsUserProfileOpen(false);
              setIsMenuOpen(false);
              setIsNavbarLight(false);
            }}
          />
        </div>
        <UserMenu containerStyle={styles.userMenuStyle} />
      </Modal>
    );
  };

  const renderProfileModal = () => {
    return (
      <PopOver
        show={isUserAuthModalOpen}
        reference={userAuthRef}
        onClose={() => setIsUserAuthModalOpen(false)}
        containerStyle={styles.profileModalStyle}
        placement="bottom-end"
      >
        {user ? (
          <UserMenu />
        ) : (
          <div className={styles.signInUpBlockStyle}>
            <div className={styles.signInUpHeaderStyle}>
              <p className={styles.signInUpHeaderTitleStyle}>Sign up or Log in</p>
              <Image src={closeGrayIcon} onClick={() => setIsUserAuthModalOpen(false)} />
            </div>
            <div className={styles.signInUpBodyStyle}>
              <Image src={addUserBlueIcon} containerStyle={styles.signInUpBodyIconStyle} />
              <p className={styles.signInUpTextStyle}>
                Create an account or Log in with an existing account to access all of Foundi’s
                exciting features.
              </p>
              <div className={styles.signInUpBtnWrapperStyle}>
                <Button
                  title="Login"
                  variant="light"
                  btnStyle={styles.signInUpBtnStyle}
                  onClick={() => navigate(routePaths.login)}
                />
                <Button
                  title="Signup"
                  btnStyle={styles.signInUpBtnStyle}
                  onClick={() => navigate(routePaths.signup)}
                />
              </div>
            </div>
          </div>
        )}
      </PopOver>
    );
  };

  const renderLanguageModal = () => {
    return (
      <PopOver
        show={isLangModalOpen}
        reference={langRef}
        containerStyle={styles.langModalStyle}
        onClose={() => setIsLangModalOpen(false)}
        placement="bottom-end"
      >
        {languages.map((item, index) => (
          <div key={index} className={styles.langItemStyle} onClick={() => setLanguage(item.val)}>
            <p
              className={classNames(
                styles.langItemTitleStyle,
                item.val === currentLanguage ? styles.selectedLangItemTitleStyle : ''
              )}
            >
              {item.title}
            </p>
            <Image src={item.val === currentLanguage ? checkBlueIcon : checkGrayIcon} />
          </div>
        ))}
      </PopOver>
    );
  };

  const renderCartModal = () => {
    return (
      <Modal
        show={isCartModalOpen}
        reference={cartRef}
        containerStyle={styles.cartModalStyle}
        onClose={() => setIsCartModalOpen(false)}
        closeOnOutSideClick
        showOverlay
        placement="bottom-end"
      >
        <Cart closeAction={() => setIsCartModalOpen(false)} />
      </Modal>
    );
  };

  return (
    <div
      className={classNames(
        styles.containerStyle,
        isNavbarLight ? styles.navContainerLightStyle : ''
      )}
    >
      <div className={styles.navSectionStyle}>
        <Image
          src={isNavbarLight ? foundiBlackIcon : foundiWhiteIcon}
          onClick={() => navigate('/')}
        />
        {width > 867 && (
          <div className={styles.middleSectionStyle}>
            <NavLink
              to={routePaths.events}
              className={classNames(
                styles.navLinkStyle,
                pathname === routePaths.events && styles.activeLinkStyle
              )}
            >
              Events
            </NavLink>
            <NavLink
              to={routePaths.about}
              className={classNames(
                styles.navLinkStyle,
                pathname === routePaths.about && styles.activeLinkStyle
              )}
            >
              About
            </NavLink>
            <NavLink
              to={routePaths.products}
              className={classNames(
                styles.navLinkStyle,
                pathname === routePaths.products && styles.activeLinkStyle
              )}
            >
              Products
            </NavLink>
          </div>
        )}
        <div className={styles.rightSectionStyle}>
          {width > 867 && (
            <Image
              src={globeWhiteIcon}
              containerStyle={styles.navIconStyle}
              reference={setLangRef}
              onClick={() => setIsLangModalOpen(true)}
            />
          )}
          <Image
            src={isNavbarLight ? cartBlackIcon : cartWhiteIcon}
            containerStyle={styles.navIconStyle}
            reference={setCartRef}
            onClick={() => (user ? setIsCartModalOpen(true) : navigate(routePaths.login))}
          />
          {width > 867 && (
            <Image
              src={userWhiteIcon}
              containerStyle={styles.navIconStyle}
              reference={setUserAuthRef}
              onClick={() => setIsUserAuthModalOpen(true)}
            />
          )}
          {width <= 867 && (
            <Image
              src={
                isNavbarLight
                  ? isMenuOpen
                    ? closeBlackIcon
                    : hamburgerBlackIcon
                  : hamburgerWhiteIcon
              }
              containerStyle={styles.navIconStyle}
              onClick={handleMenuClick}
            />
          )}
          {renderMenuModal()}
          {renderProfileModal()}
          {renderLanguageModal()}
          {renderCartModal()}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
