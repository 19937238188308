import axios from '../../utils/axios';
import * as endPoints from '../endpoints';

export const getUserDetailsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getUserDetails.replace('{id}', id)));

export const updateUserDataApi = async (data) =>
  Promise.resolve(axios.put(endPoints.updateUserData, data));

export const updatePasswordApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updatePassword, data));

export const uploadImageApi = async (data) =>
  Promise.resolve(axios.post(endPoints.uploadImage, data));

export const resendVerifictionLinkApi = async (data) =>
  Promise.resolve(axios.post(endPoints.resendVerifyLink, data));

export const checkIsUserGuestApi = async (data) =>
  Promise.resolve(axios.post(endPoints.checkGuest, data));
