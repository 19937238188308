import React from 'react';
import { arrowRightCornerIcon } from 'resources/images';
import Image from 'components/common/image';
import styles from './styles.module.css';

const SustainabilitySection = () => {
  return (
    <div className={styles.posterViewStyle}>
      <div className={styles.posterSubViewStyle}>
        <div className={styles.contentWrapperStyle}>
          <div className={styles.contentSubWrapperStyle}>
            <p className={styles.titleTextStyle}>Sustainability is our core belief.</p>
            <div className={styles.descTextViewStyle}>
              <p className={styles.descTextStyle}>
                Make a difference to be the difference. We put 120% of our effort towards Product,
                Environmental, and Social sustainability.
              </p>
              <div className={styles.playViewStyle}>
                <Image src={arrowRightCornerIcon} containerStyle={styles.imagContainerStyle} />
                <p className={styles.playTextStyle}>Learn more</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SustainabilitySection;
