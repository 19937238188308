import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import Image from 'components/common/image';
import Button from 'components/common/button';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal';
import { closeWhiteIcon, flashWhiteIcon } from 'resources/images';
import styles from './styles.module.css';

const Scanner = (props) => {
  const {
    handleScan = () => {},
    onCloseScanner = () => {},
    alertModal,
    onCloseRescanModal = () => {},
  } = props;
  const [isFlash, setIsFlash] = useState(false);

  const handleError = (err) => {
    try {
      console.log(err.message);
    } catch (error) {}
  };

  const setTorch = () => {
    const SUPPORTS_MEDIA_DEVICES = 'mediaDevices' in navigator;
    if (SUPPORTS_MEDIA_DEVICES) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const cameras = devices.filter((device) => device.kind === 'videoinput');
          if (cameras.length === 0) {
            console.log('No camera found on this device.');
          }
          navigator.mediaDevices
            .getUserMedia({
              video: {
                facingMode: 'environment',
              },
            })
            .then((stream) => {
              const track = stream.getVideoTracks()[0];
              const imageCapture = new ImageCapture(track);
              imageCapture
                .getPhotoCapabilities()
                .then((capabilities) => {
                  const torchSupported =
                    !!capabilities.torch ||
                    ('fillLightMode' in capabilities &&
                      capabilities.fillLightMode.length !== 0 &&
                      capabilities.fillLightMode !== 'none');
                  if (torchSupported) {
                    try {
                      track.applyConstraints({
                        advanced: [
                          {
                            torch: !isFlash,
                          },
                        ],
                      });
                      setIsFlash(!isFlash);
                    } catch (err) {
                      console.log(err);
                    }
                  } else {
                    console.log('No torch found');
                  }
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  };

  const renderRescanSection = () => {
    return (
      <Modal
        show={alertModal}
        containerStyle={styles.scanResultModalStyle}
        onClose={onCloseRescanModal}
        closeOnOutSideClick
        showOverlay
      >
        <div className={styles.scanResultTextWrapperStyle}>
          <p className={styles.scanResultTextStyle}>Something went wrong</p>
          <p className={styles.scanResultDescStyle}>
            Scanned QR is either invalid or not related to Foundi. Re-Scan or Enter SNO code
            manually.
          </p>
        </div>
        <div className={styles.scanResultBtnWrapperStyle}>
          <Button title="Re-scan" btnStyle={styles.btnStyle} />
          <Button
            title="SNO"
            onClick={() => onCloseScanner()}
            variant="gray"
            btnStyle={styles.btnStyle}
          />
        </div>
      </Modal>
    );
  };
  return (
    <div className={styles.scannerSectionStyle}>
      <QrReader
        scanDelay={2000}
        onError={handleError}
        onScan={handleScan}
        className={styles.qrReaderStyle}
        ViewFinder={'section'}
      />
      <div className={styles.scanBarStyle}>
        <em></em>
        <span></span>
      </div>
      <div className={styles.scannerOptionsViewStyle}>
        <div className={styles.scannerOptionsSubViewStyle}>
          <Image
            src={closeWhiteIcon}
            alt="X"
            containerStyle={styles.scannerIconsViewStyle}
            onClick={() => {
              navigator.mediaDevices.getUserMedia({ video: true }).then((stream) =>
                stream.getTracks().forEach((track) => {
                  console.log(track);
                  track.stop();
                })
              );
              onCloseScanner();
            }}
          />
          <Image
            src={flashWhiteIcon}
            alt="img"
            containerStyle={styles.scannerIconsViewStyle}
            onClick={() => setTorch()}
          />
        </div>
      </div>
      <div className={styles.scannerBottomOptionsViewStyle}>
        <div className={styles.scannerBottomOptionsSubViewStyle}>
          <Button
            title="Point at a Foundi QR"
            btnStyle={styles.scannerBtnViewStyle}
            onClick={() => console.log('as')}
          />
        </div>
      </div>
      {renderRescanSection()}
    </div>
  );
};

Scanner.propTypes = {
  handleScan: PropTypes.func,
  alertModal: PropTypes.bool,
  onCloseRescanModal: PropTypes.func,
  onCloseScanner: PropTypes.func,
};

export default Scanner;
