import React, { createContext, useState, useMemo, useCallback, useEffect, useContext } from 'react';
import { getDataFromToken } from 'utils/jwt';
import { getUserDetailsApi } from 'networking/api/user';
import { setAxiosSession } from 'utils/auth';
import { loginpApi } from 'networking/api/auth';

const AuthContext = createContext();

const AuthProvider = (props) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [authError, setAuthError] = useState(null);

  const initialize = useCallback(async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const userData = localStorage.getItem('user');
      if (userData) {
        setUser(JSON.parse(userData));
      }
      if (token) {
        setAxiosSession(token);
        const tokenData = await getDataFromToken(token);
        let response = await getUserDetailsApi(tokenData.id);
        if (response.data.type === 'success') {
          setUser(response.data.data);
          setAxiosSession(token);
          setIsLoggedIn(true);
        } else {
          setAuthError(response.data.message);
        }
        setIsInitialized(true);
      } else {
        setIsInitialized(true);
        setIsLoggedIn(false);
      }
    } catch (error) {
      // console.log('error', error.message);
      setIsInitialized(true);
      setIsLoggedIn(false);
      setAuthError(error.message);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const userLogin = useCallback(async (loginData) => {
    try {
      setAuthError(null);
      let response = await loginpApi(loginData);
      const { type, user, auth, accessToken, message } = response.data;
      if (type === 'success') {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('user', JSON.stringify(user));
        setIsLoggedIn(auth);
        setUser(user);
        setAxiosSession(accessToken);
      } else {
        setAuthError(response.data.message);
        setUser(null);
        setIsLoggedIn(false);
      }
    } catch (error) {
      setUser(null);
      setAuthError(error.message);
      setIsLoggedIn(false);
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('accessToken');
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('company');
    setAxiosSession(null);
    setIsLoggedIn(false);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized,
      isLoggedIn,
      user,
      authError,
      setUser,
      setIsLoggedIn,
      setAuthError,
      userLogin,
      logout,
    }),
    [
      isInitialized,
      isLoggedIn,
      user,
      authError,
      setUser,
      setIsLoggedIn,
      setAuthError,
      userLogin,
      logout,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return context;
};
