import React from 'react';
import styles from './styles.module.css';
import { productSampleIcon } from 'resources/images';
import { useStrings } from 'providers/stringsprovider';
import Image from 'components/common/image';
import { useNavigate } from 'react-router';
import routePaths from 'routing/paths';
import { useUserData } from 'providers/userdataprovider';
const SampleProducts = () => {
  const { strings } = useStrings();
  const navigate = useNavigate();
  const { products, setProducts, setCartData, setOrders } = useUserData();

  const displayPrice = (val, currency = 'usd') => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(val);
  };

  const renderProductsContainer = () => {
    return (
      <div className={styles.simpleHeaderContainerStyle}>
        <p className={styles.simpleHeaderTextStyle}>Our best sellers</p>
        <p className={styles.simpleDescriptionTextStyle}>{strings.homeFeaturedProductsDesc}</p>
      </div>
    );
  };
  const renderProducts = () => {
    return (
      <React.Fragment>
        <div className={styles.productsMainContainerStyle}>
          {products.slice(0, 3).map((item, index) => {
            return (
              <div key={index} className={styles.productContainerStyle}>
                <Image src={item.images.thumbnail} containerStyle={styles.imgContainerStyle} />
                <p className={styles.productNameTextStyle}>{item.name}</p>
                <div className={styles.priceViewStyle}>
                  <p className={styles.productPriceStyle}>
                    {displayPrice(item?.price.selling_price, item?.price.currency)}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <a
          onClick={() => navigate(routePaths.products)}
          className={styles.browseAllProductsAnchorStyle}
        >
          <p className={styles.browseAllProductsStyle}>{strings.browseAllProducts}</p>
        </a>
      </React.Fragment>
    );
  };
  return (
    <div className={styles.containerStyle}>
      {renderProductsContainer()}
      {renderProducts()}
    </div>
  );
};

export default SampleProducts;
