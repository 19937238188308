import axios from '../../utils/axios';
import * as endPoints from '../endpoints';

export const checkQrApi = async (data) => Promise.resolve(axios.post(endPoints.checkQR, data));

export const getItemDetailsBySnoOrIdApi = async (data) =>
  Promise.resolve(axios.post(endPoints.getItemDetailsBySnoOrId, data));

export const getCategoriesApi = async () => Promise.resolve(axios.get(endPoints.getCategories));

export const addItemApi = async (data) => Promise.resolve(axios.post(endPoints.addItem, data));

export const updateItemApi = async (data) => Promise.resolve(axios.put(endPoints.updateItem, data));

export const getItemsApi = async (user_id) =>
  Promise.resolve(axios.get(endPoints.getItems.replace('{user_id}', user_id)));

export const deleteItemApi = async (id) =>
  Promise.resolve(axios.delete(endPoints.deleteItem.replace('{id}', id)));

export const updateItemLostStateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateItemLostState, data));

export const updateItemConversationStateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateItemConversationState, data));
