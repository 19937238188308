import {
  audioIcon,
  bookIcon,
  electronicsIcon,
  healthKitIcon,
  keyIcon,
  luggageIcon,
  packIcon,
  sportsIcon,
  walleetIcon,
} from 'resources/images';

export const getItemIcon = (data) => {
  if (data === 'Health') {
    return healthKitIcon;
  } else if (data === 'Wallet') {
    return walleetIcon;
  } else if (data === 'Sports/toys') {
    return sportsIcon;
  } else if (data === 'Books/files') {
    return bookIcon;
  } else if (data === 'Luggage') {
    return luggageIcon;
  } else if (data === 'Essentials' || data === 'Keychain') {
    return keyIcon;
  } else if (data === 'Audio') {
    return audioIcon;
  } else if (data === 'Electronics') {
    return electronicsIcon;
  } else {
    return packIcon;
  }
};
