import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { addUserBlueIcon, closeGrayIcon, gridMenuBlackIcon, homeBlackIcon } from 'resources/images';
import { useUserData } from 'providers/userdataprovider';
import Image from 'components/common/image';
import UserMenu from 'components/user-menu';
import PopOver from 'components/common/popover';
import Loader from 'components/common/loader';
import { useAuth } from 'providers/authprovider';
import styles from './styles.module.css';
import routePaths from 'routing/paths';
import Button from 'components/common/button';
import classNames from 'classnames';

const Layout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { loading } = useUserData();
  const [isUserMenuModalOpen, setIsUserMenuModalOpen] = useState(false);
  const [userMenuRef, setUserMenuRef] = useState(null);

  const renderProfileModal = () => {
    return (
      <PopOver
        show={isUserMenuModalOpen}
        showOverlay
        reference={userMenuRef}
        onClose={() => setIsUserMenuModalOpen(false)}
        containerStyle={styles.profileModalStyle}
        placement="top-start"
      >
        <div className={styles.profileWrapperStyle}>
          {user && (
            <div className={styles.closeIconViewStyle}>
              <Image
                src={closeGrayIcon}
                onClick={() => setIsUserMenuModalOpen(false)}
                containerStyle={styles.closeIconStyle}
              />
            </div>
          )}
          {user ? (
            <UserMenu setState={setIsUserMenuModalOpen} />
          ) : (
            <div className={styles.signInUpBlockStyle}>
              <div className={styles.signInUpHeaderStyle}>
                <p className={styles.signInUpHeaderTitleStyle}>Sign up or Log in</p>
                <Image src={closeGrayIcon} onClick={() => setIsUserMenuModalOpen(false)} />
              </div>
              <div className={styles.signInUpBodyStyle}>
                <Image src={addUserBlueIcon} containerStyle={styles.signInUpBodyIconStyle} />
                <p className={styles.signInUpTextStyle}>
                  Create an account or Log in with an existing account to access all of Foundi’s
                  exciting features.
                </p>
                <div className={styles.signInUpBtnWrapperStyle}>
                  <Button
                    title="Login"
                    variant="light"
                    btnStyle={styles.signInUpBtnStyle}
                    onClick={() => navigate(routePaths.login)}
                  />
                  <Button
                    title="Signup"
                    btnStyle={styles.signInUpBtnStyle}
                    onClick={() => navigate(routePaths.signup)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </PopOver>
    );
  };
  return (
    <div className={styles.layoutStyle}>
      {loading && <Loader />}
      <div
        className={classNames(
          pathname === routePaths.checkout ? styles.customBgStyle : styles.contenWrappertStyle
        )}
      >
        {pathname !== routePaths.checkout && (
          <div className={styles.leftContentWrapperStyle}>
            <div className={styles.menuIconViewStyle} ref={setUserMenuRef}>
              <Image
                src={gridMenuBlackIcon}
                onClick={() => setIsUserMenuModalOpen(true)}
                alt="icon"
                containerStyle={styles.iconStyle}
              />
              {renderProfileModal()}
            </div>
            <div className={styles.menuIconViewStyle} onClick={() => navigate('/')}>
              <Image src={homeBlackIcon} alt="icon" containerStyle={styles.iconStyle} />
            </div>
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
