import React from 'react';
import NavBar from 'components/navbar';
import styles from './styles.module.css';

const Events = () => {
  return (
    <div className={styles.containerStyle}>
      <NavBar />
      <div className={styles.subContainerStyle}>
        <h1 className={styles.headerTextStyle}>Coming Soon</h1>
      </div>
    </div>
  );
};

export default Events;
