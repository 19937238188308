import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { verifyEmailApi } from 'networking/api/auth';
import Button from 'components/common/button';
import { useStrings } from 'providers/stringsprovider';
import { resendVerifictionLinkApi } from 'networking/api/user';
import Loader from 'components/common/loader';
import routePaths from 'routing/paths';
import { jwtDecode } from 'utils/jwt';
import styles from './styles.module.css';

const Verify = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { strings } = useStrings();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [data, setData] = useState(null);

  useEffect(() => {
    try {
      setData(jwtDecode(token));
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    async function handleVerify() {
      try {
        setLoading(true);
        let response = await verifyEmailApi({ token });
        setLoading(false);
        setStatus(response.data.type);
      } catch (err) {
        setLoading(false);
        setStatus(err?.type);
      }
    }
    handleVerify();
  }, []);

  const resendVerificationLink = async () => {
    try {
      let decoded_data = jwtDecode(token);
      let response = await resendVerifictionLinkApi({
        email: decoded_data.email,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const getStatus = () => {
    if (status == 'success') {
      return (
        <div className={styles.contentWrapperStyle}>
          <h2 className={styles.headerTextStyle}>{strings.cheers}</h2>
          <p className={styles.descTextStyle}>{strings.cheersDesc}</p>
          <Button
            title={strings.takeMeToHome}
            btnStyle={styles.btnStyle}
            onClick={() => navigate(routePaths.home)}
          />
        </div>
      );
    } else if (status == 'error') {
      return (
        <div className={styles.contentWrapperStyle}>
          <h2 className={styles.headerTextStyle}>{strings.oopsHeaderText}</h2>
          <p className={styles.descTextStyle}>
            {strings.confirmationText + ' ' + data?.email + ' ' + strings.brokenLinkText}
          </p>
          <p className={styles.descTextStyle}>{strings.oopsDescText}</p>
          <Button
            title={strings.resendLink}
            btnStyle={styles.btnStyle}
            onClick={resendVerificationLink}
          />
        </div>
      );
    }
  };

  return (
    <div className={styles.containerStyle}>
      {loading ? <Loader /> : null}
      {getStatus()}
    </div>
  );
};

export default Verify;
