// User ENDPOINTS
export const login = '/auth/login';
export const register = '/auth/register';
export const getUserDetails = '/user/get-user-data/{id}';
export const updateUserData = '/user/update-profile';
export const updatePassword = '/auth/change-password';
export const uploadImage = '/uploads/image';
export const resendVerifyLink = '/user/send-verification-link';
export const verifyEmail = '/auth/verify';
export const checkGuest = '/auth/isguest';

// Item EndPoints
export const checkQR = '/qr/check-qrcode';
export const getItemDetailsBySnoOrId = '/products/get-details-by-sno-or-id';
export const getCategories = '/categories/get';
export const addItem = '/products/add';
export const updateItem = '/products/update';
export const getItems = '/products/get/{user_id}';
export const deleteItem = '/products/delete/{id}';
export const updateItemLostState = '/products/update-lost-status';
export const updateItemConversationState = '/products/update-conversation-state';

// chat EndPoints
export const getChatList = '/chat/getchatlist/{user_id}';
export const getChatMessages = '/chat/getmessages/{chat_id}';
export const sendChatMessage = '/chat/send';
export const updateMessageReadStatus = '/chat/messages/updatereadstatus';
export const getChatInfo = '/chat/getchatinfo/{id}';

// e-commerce endpoints
export const getEcommerceCategories = '/admin/ecom/category/get/all';
export const getEcommerceProducts = '/admin/ecom/product/get/all';

// user cart endpoints
export const getUserCartData = '/cart/get/{id}';
export const addItemsToCart = '/cart/add';

// user order endpoints
export const getUsersOrders = '/orders/get/{id}';
export const buyItems = '/orders/buy';
