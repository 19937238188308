function path(root, sublink) {
  return `${root}${sublink}`;
}

const routePaths = {
  login: '/login',
  signup: '/register',

  home: '/',
  events: '/events',
  about: '/about',
  privacy: '/privacy',
  products: '/products',
  profile: '/profile',
  notifications: '/notifications',
  addOrReturnItem: '/add-or-return-item',
  chat: '/chat',
  myPurchases: '/my-purchases',
  myItems: '/my-items',
  emailVerification: '/email-verification',
  verify: '/verify/:token',
  checkout: '/checkout',

  // createAsset: path(asset_root, '/create'),
  // assetDetails: (id) => path(asset_root, `/details/${id}`),
};

export default routePaths;
