import React, { useEffect } from 'react';
import { useRoutes, Navigate, useLocation } from 'react-router-dom';
import GuestGuard from 'guards/guestguard';
import Layout from 'layouts';
import Home from 'pages/home';
import Login from 'pages/login';
import Signup from 'pages/signup';
import Profile from 'pages/profile';
import About from 'pages/about';
import Events from 'pages/events';
import Products from 'pages/products';
import Privacy from 'pages/privacy';
import routePaths from 'routing/paths';
import Notifications from 'pages/notifications';
import AddOrReturnItem from 'pages/add-or-return-item';
import Chat from 'pages/chat';
import MyPurchases from 'pages/my-purchases';
import MyItems from 'pages/my-items';
import VerifyEmail from 'pages/verify-email';
import AuthGuard from 'guards/authguard';
import Verify from 'pages/verify';
import CheckOut from 'pages/checkout';

const AppRoutes = () => {
  const routes = useRoutes([
    { path: routePaths.home, element: <Home /> },
    {
      path: routePaths.about,
      element: <About />,
    },
    {
      path: routePaths.events,
      element: <Events />,
    },
    {
      path: routePaths.products,
      element: <Products />,
    },
    {
      path: routePaths.privacy,
      element: <Privacy />,
    },
    {
      path: routePaths.verify,
      element: <Verify />,
    },
    {
      path: routePaths.emailVerification,
      element: <VerifyEmail />,
    },
    {
      path: routePaths.login,
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: routePaths.home,
      element: (
        <AuthGuard>
          <Layout />
        </AuthGuard>
      ),
      children: [
        {
          path: routePaths.profile,
          element: <Profile />,
        },
        {
          path: routePaths.notifications,
          element: <Notifications />,
        },
        {
          path: routePaths.myItems,
          element: <MyItems />,
        },
        {
          path: routePaths.addOrReturnItem,
          element: <AddOrReturnItem />,
        },
        {
          path: routePaths.myPurchases,
          element: <MyPurchases />,
        },
        {
          path: routePaths.checkout,
          element: <CheckOut />,
        },
      ],
    },

    {
      path: routePaths.chat,
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Chat />,
        },
      ],
    },
    {
      path: routePaths.signup,
      element: <Signup />,
    },
    // {
    //   path: "/chat",
    //   element: <ChatPage />,
    // },
    // {
    //   path: "",
    //   element: (
    //     <AuthGuard>
    //       <Layout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     {
    //       path: "/checkout",
    //       element: <CheckOutPage />,
    //     },
    //   ],
    // },
  ]);

  return routes;
};

export default AppRoutes;
