import React from 'react';
import Image from 'components/common/image';
import {
  addItemTagIcon,
  appleStoreIcon,
  connectTagIcon,
  googlePlayStoreIcon,
  purchaseTagIcon,
  recoverTagIcon,
} from 'resources/images';
import styles from './styles.module.css';
const WorkingFlow = () => {
  const renderPlayStoreBtnSection = () => {
    return (
      <div className={styles.appStoreWrapperStyle}>
        <Image src={appleStoreIcon} containerStyle={styles.storeIocnStyle} />
        <Image src={googlePlayStoreIcon} containerStyle={styles.storeIocnStyle} />
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      <div className={styles.headerViewStyle}>
        <p className={styles.headerTextStyle}>Ah, it’s so simple!</p>
        <p className={styles.headerDescTextStyle}>
          Still unsure how Foundi works? Don’t worry, these 4 simple steps will get you up to speed.
        </p>
      </div>
      {/* cards section  */}
      <div className={styles.eachCardSectionStyle}>
        <div className={styles.eachSectionLeftViewStyle}>
          <p className={styles.eachSectionTitleStyle}>01 - Purchase tags</p>
          <p className={styles.eachSectionDescTextStyle}>
            Purchase physical Foundi tags from our app or the website. These tags will allow you to
            register your belongings within our database.
          </p>
        </div>
        <Image src={purchaseTagIcon} containerStyle={styles.eachSectionImgStyle} />
      </div>
      <div className={styles.eachCardSectionStyle2}>
        <Image src={addItemTagIcon} containerStyle={styles.eachSectionImgStyle} />
        <div className={styles.eachSectionLeftViewStyle}>
          <p className={styles.eachSectionTitleStyle}>02 - Add items</p>
          <p className={styles.eachSectionDescTextStyle}>
            Attach the purchased Foundi-tags to your belongings & scan the QR code on it to register
            them in our database.
          </p>
        </div>
      </div>
      <div className={styles.eachCardSectionStyle}>
        <div className={styles.eachSectionLeftViewStyle}>
          <p className={styles.eachSectionTitleStyle}>03 - Connect</p>
          <p className={styles.eachSectionDescTextStyle}>
            If you lost your registered item, finder(s) can scan the QR (or enter SNO) present on
            the tag and can reach you anonymously via our inbuilt messenger.
          </p>
        </div>
        <Image src={connectTagIcon} containerStyle={styles.eachSectionImgStyle} />
      </div>
      <div className={styles.eachCardSectionStyle2}>
        <Image src={recoverTagIcon} containerStyle={styles.eachSectionImgStyle} />
        <div className={styles.eachSectionLeftViewStyle}>
          <p className={styles.eachSectionTitleStyle}>04 - Recover</p>
          <p className={styles.eachSectionDescTextStyle}>
            Conversing with the finder, figure out a way to recover your misplaced item. That’s it!
            We told you it is simple.
          </p>
        </div>
      </div>
      {renderPlayStoreBtnSection()}
    </div>
  );
};

export default WorkingFlow;
