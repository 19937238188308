import React from 'react';
import styles from './styles.module.css';
import { privacyIcon } from 'resources/images';
import { useStrings } from '../../providers/stringsprovider';

const Privacy = () => {
  const { strings } = useStrings();

  const renderBannerSection = () => {
    return (
      <div className={styles.privacyBannerSectionStyle}>
        <div className={styles.privacyImgWrapperStyle}>
          <div className={styles.privacyImgStyle}>
            <img src={privacyIcon} className={styles.imgStyle} />
          </div>
        </div>
        <div className={styles.privacyDescWrapperStyle}>
          <p className={styles.headerTextStyle}>{strings.privacyBannerHeaderText}</p>
          <div className={styles.descWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.privacyBannerDescOne}</p>
            <p className={styles.descTextStyle}>{strings.privacyBannerDescTwo}</p>
            <p className={styles.descTextStyle}>{strings.privacyBannerDescThree}</p>
            <p className={styles.descTextStyle}>{strings.privacyBannerDescFour}</p>
          </div>
        </div>
      </div>
    );
  };
  const renderPolicySection = () => {
    return (
      <div className={styles.privacyContainerStyle}>
        <div className={styles.privacyHeaderWrapperStyle}>
          <p className={styles.privacyHeaderTextStyle}>{strings.privacyHeaderText}</p>
          <p className={styles.lastUpdatedTextStyle}>
            {strings.lastUpdated}:&nbsp;
            <span className={styles.lastUpdatedDateTextStyle}>Dec 30th 2022,&nbsp;</span>
            {strings.lastReviewed}:&nbsp;
            <span className={styles.lastUpdatedDateTextStyle}>Dec 20th 2022</span>
          </p>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.dataController}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.dataControllerDescOne}</p>
            <div>
              <p className={styles.descTextStyle}>
                -&nbsp;
                <a className={styles.linkTextStyle}>{strings.dataControllerDescTwo}</a>
                &nbsp;
                {strings.site}
              </p>
              <p className={styles.descTextStyle}>{strings.dataControllerDescThree}</p>
              <p className={styles.descTextStyle}>{strings.dataControllerDescFour}</p>
            </div>
            <p className={styles.descTextStyle}>{strings.dataControllerDescFive}</p>
          </div>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.aboutFoundi}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.aboutFoundiDescOne}</p>
            <p className={styles.descTextStyle}>{strings.aboutFoundiDescTwo}</p>
          </div>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.categoryText}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.categoryDescOne}</p>
            <div>
              <p className={styles.descTextStyle}>{strings.categoryDescTwo}</p>
              <p className={styles.descTextStyle}>{strings.categoryDescThree}</p>
              <p className={styles.descTextStyle}>{strings.categoryDescFour}</p>
              <p className={styles.descTextStyle}>{strings.categoryDescFive}</p>
              <p className={styles.descTextStyle}>{strings.categoryDescSix}</p>
              <p className={styles.descTextStyle}>{strings.categoryDescSeven}</p>
              <p className={styles.descTextStyle}>{strings.categoryDescEight}</p>
              <p className={styles.descTextStyle}>{strings.categoryDescNine}</p>
              <p className={styles.descTextStyle}>{strings.categoryDescTen}</p>
            </div>
            <p className={styles.descTextStyle}>{strings.categoryDescEleven}</p>

            <p className={styles.descTextStyle}>{strings.categoryDescTwelve}</p>
          </div>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.purposeText}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.purposeDescOne}</p>
            <p className={styles.descTextStyle}>{strings.purposeDescTwo}</p>
            <div>
              <p className={styles.descTextStyle}>{strings.purposeDescThree}</p>
              <p className={styles.descTextStyle}>{strings.purposeDescFour}</p>
              <p className={styles.descTextStyle}>{strings.purposeDescFive}</p>
              <p className={styles.descTextStyle}>{strings.purposeDescSix}</p>
            </div>
          </div>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.dataRetentionText}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.dataRetentionDescOne}</p>
            <p className={styles.descTextStyle}>
              {strings.dataRetentionDescTwo}&nbsp;
              <a className={styles.linkTextStyle}>{strings.privacyLink}</a>
            </p>
          </div>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.employementApplicationText}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.employementAppDesc}</p>
          </div>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.thirdPartiesText}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.thirdPartiesDescOne}</p>
            <div>
              <p className={styles.descTextStyle}>{strings.thirdPartiesDescTwo}</p>
              <p className={styles.descTextStyle}>{strings.thirdPartiesDescThree}</p>
              <p className={styles.descTextStyle}>{strings.thirdPartiesDescFour}</p>
              <p className={styles.descTextStyle}>{strings.thirdPartiesDescFive}</p>
              <p className={styles.descTextStyle}> {strings.thirdPartiesDescSix}</p>
              <p className={styles.descTextStyle}> {strings.thirdPartiesDescSeven}</p>
              <p className={styles.descTextStyle}>{strings.thirdPartiesDescEight}</p>
              <p className={styles.descTextStyle}>{strings.thirdPartiesDescNine}</p>
              <p className={styles.descTextStyle}> {strings.thirdPartiesDescTen}</p>
              <p className={styles.descTextStyle}>{strings.thirdPartiesDescEleven}</p>
              <p className={styles.descTextStyle}>{strings.thirdPartiesDescTwelve}</p>
            </div>
            <p className={styles.descTextStyle}>{strings.thirdPartiesDescThirteen}</p>
            <p className={styles.descTextStyle}>{strings.thirdPartiesDescFourteen}</p>
            <p className={styles.descTextStyle}>
              {strings.thirdPartiesDescFifteen}
              <span className={styles.linkTextStyle}>{strings.privacyLink}</span>
            </p>
          </div>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.cookiesText}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.cookiesDescOne}</p>
            <p className={styles.descTextStyle}>{strings.cookiesDescTwo}</p>
            <p className={styles.descTextStyle}>{strings.cookiesDescThree}</p>
            <p className={styles.descTextStyle}>{strings.cookiesDescFour}</p>
            <p className={styles.descTextStyle}>{strings.cookiesDescFive}</p>
            <div>
              <p className={styles.descTextStyle}>
                <span className={styles.blueTextStyle}>{strings.cookiesDescSix}</span>
              </p>
              <p className={styles.descTextStyle}>
                <span className={styles.blueTextStyle}>{strings.cookiesDescSeven}</span>
              </p>
              <p className={styles.descTextStyle}>
                <span className={styles.blueTextStyle}>{strings.cookiesDescEight}</span>
              </p>
              <p className={styles.descTextStyle}>
                <span className={styles.blueTextStyle}>{strings.cookiesDescNine}</span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.cyberSecurityText}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.cyberSecurityDesc}</p>
          </div>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.yourRightsText}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.yourRightsDescOne}</p>
            <div>
              <p className={styles.descTextStyle}>{strings.yourRightsDescTwo}</p>
              <p className={styles.descTextStyle}>{strings.yourRightsDescThree}</p>
              <p className={styles.descTextStyle}>{strings.yourRightsDescFour}</p>
              <p className={styles.descTextStyle}>{strings.yourRightsDescFive}</p>
              <p className={styles.descTextStyle}>{strings.yourRightsDescSix}</p>
              <p className={styles.descTextStyle}>{strings.yourRightsDescSeven}</p>
              <p className={styles.descTextStyle}>{strings.yourRightsDescEight}</p>
            </div>
            <p className={styles.descTextStyle}>
              {strings.yourRightsDescNine}
              <span className={styles.linkTextStyle}>{strings.privacyLink}</span>
            </p>
            <p className={styles.descTextStyle}>{strings.yourRightsDescTen}</p>
            <p className={styles.descTextStyle}>
              {strings.yourRightsDescEleven}
              <span className={styles.linkTextStyle}> {strings.imyLink}</span> and
              <span className={styles.linkTextStyle}> {strings.europaLink}</span>
            </p>
          </div>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.changesInPrivacyPolicyText}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>{strings.changesInPrivacyDesc}</p>
          </div>
        </div>
        <div className={styles.privacyContentWrapperStyle}>
          <p className={styles.subHeaderTextStyle}>{strings.contactInfoText}</p>
          <div className={styles.descTextWrapperStyle}>
            <p className={styles.descTextStyle}>
              {strings.contactInfoDesc}
              <span className={styles.linkTextStyle}>{strings.privacyLink}</span>
            </p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.containerStyle}>
      {renderBannerSection()}
      {renderPolicySection()}
    </div>
  );
};

export default Privacy;
