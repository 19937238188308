import React, { useState } from 'react';
import Image from 'components/common/image';
import { closeCircleFilllGrayIcon, googleIcon, hideGrayIcon, showGrayIcon } from 'resources/images';
import { useStrings } from 'providers/stringsprovider';
import Input from 'components/common/input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from 'providers/authprovider';
import Button from 'components/common/button';
import styles from './styles.module.css';
import { useNavigate } from 'react-router';
import routePaths from 'routing/paths';

const Login = () => {
  const { strings } = useStrings();
  const navigate = useNavigate();
  const { userLogin, authError } = useAuth();
  const [pwVisible, setPwVisible] = useState(false);
  const [schemaObj, setSchemaObj] = useState({
    email: Yup.string().email(strings.enterEmail).required(strings.emailRequired),
    password: Yup.string().required(strings.passwordRequired),
  });

  console.log('authError', authError);

  const loginAction = async (values, { resetForm }) => {
    console.log('values', values);
    await userLogin(values);
  };

  const loginSchema = Yup.object().shape(schemaObj);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: loginAction,
  });

  const renderHeaderSection = () => {
    return (
      <div className={styles.headerSectionStyle}>
        <p className={styles.backTextStyle} onClick={() => navigate(-1)}>
          Back
        </p>
        <Image
          src={closeCircleFilllGrayIcon}
          onClick={() => navigate(routePaths.home)}
          containerStyle={styles.closeIconStyle}
        />
      </div>
    );
  };

  const renderFormSection = () => {
    return (
      <div className={styles.formWrapperStyle}>
        <form onSubmit={formik.handleSubmit} className={styles.formSubWrapperStyle}>
          {/* top section */}
          <div className={styles.formTopSecctionStyle}>
            <p className={styles.titleTextStyle}>{strings.loginHeaderText}</p>
            <p className={styles.descTextStyle}>
              {strings.noAccount}&nbsp;
              <span onClick={() => navigate(routePaths.signup)}>{strings.signUp}</span>
            </p>
          </div>
          {/* middle Section */}
          {renderInputSection()}
          {renderButtons()}
        </form>
      </div>
    );
  };

  const renderInputSection = () => {
    return (
      <div className={styles.inputContainerStyle}>
        <Input
          name="email"
          labelText={strings.email}
          placeholder={strings.enterEmail}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
        />
        <Input
          name="password"
          type={pwVisible ? 'text' : 'password'}
          labelText={strings.password}
          placeholder={strings.enterPassword}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          rightIcon={pwVisible ? showGrayIcon : hideGrayIcon}
          onClickRightIcon={() => setPwVisible(!pwVisible)}
          hasError={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
        />
        <p className={styles.forgetTextStyle}>{strings.forgetPassword}</p>
        <p className={styles.errorMsgTextStyle}>{authError}</p>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className={styles.buttonContainerStyle}>
        <Button title={strings.logIn} type={'submit'} btnStyle={styles.btnStyle} />
        <Button
          leftIcon={googleIcon}
          title={strings.continueWithGoogle}
          btnStyle={styles.btnStyle}
          variant={'light'}
          // onClick={() => setOpen(true)}
        />
      </div>
    );
  };
  return (
    <div className={styles.loginContainerStyle}>
      <div className={styles.subContainerSyle}>
        {renderHeaderSection()}
        {renderFormSection()}
      </div>
    </div>
  );
};

export default Login;
