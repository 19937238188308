import React from 'react';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { useStrings } from '../../providers/stringsprovider';
import { fbIcon, instaIcon, linkedInIcon, twitchIcon, twitterIcon } from 'resources/images';
import routePaths from 'routing/paths';

const Footer = () => {
  const { strings } = useStrings();
  const navigate = useNavigate();
  return (
    <div className={styles.outsideFooterContainerStyle}>
      <div className={styles.footerContainerStyle}>
        <div className={styles.footerLinksStyle}>
          <a className={styles.footerlinkwrapperStyle}>
            <span className={styles.footerlinkEmojiStyle}>🤝</span>
            <p className={styles.footerlinkTextStyle}>{strings.footerJoinCommunity}</p>
          </a>
          <a className={styles.footerlinkwrapperStyle}>
            <span className={styles.footerlinkEmojiStyle}>📧</span>
            <p className={styles.footerlinkTextStyle}>{strings.footerMail}</p>
          </a>
          <a className={styles.footerlinkwrapperStyle}>
            <span className={styles.footerlinkEmojiStyle}>🤙</span>
            <p className={styles.footerlinkTextStyle}>{strings.footerMobileNo}</p>
          </a>
          <a className={styles.footerlinkwrapperStyle}>
            <span className={styles.footerlinkEmojiStyle}>📍</span>
            <p className={styles.footerlinkTextStyle}>{strings.footerVisitOffice}</p>
          </a>
        </div>
        <div className={styles.footerBottomContainer1Style}>
          <div className={styles.policyContainerStyle}>
            <a className={styles.privacyTextStyle} onClick={() => navigate(routePaths.privacy)}>
              {strings.privacyPolicy}
            </a>
            <a className={styles.privacyTextStyle}>{strings.termsOfService}</a>
          </div>
          <div className={styles.socialContainerStyle}>
            <div className={styles.socialIconWrapperStyle}>
              <img src={fbIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={instaIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={twitterIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={linkedInIcon} className={styles.imgStyle} />
            </div>
          </div>
          <div>
            <p className={styles.copyRightsTextStyle}>{strings.allRightsReserved}</p>
          </div>
        </div>
        <div className={styles.footerBottomContainer2Style}>
          <div className={styles.socialContainer2Style}>
            <div className={styles.socialIconWrapperStyle}>
              <img src={fbIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={instaIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={twitterIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={linkedInIcon} className={styles.imgStyle} />
            </div>
          </div>
          <div className={styles.policyContainer2Style}>
            <a className={styles.privacyTextStyle}>{strings.privacyPolicy}</a>
            <a className={styles.privacyTextStyle}>{strings.termsOfService}</a>
          </div>

          <div>
            <p className={styles.copyRightsTextStyle}>{strings.allRightsReserved}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
